import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useSelector } from "react-redux";
import "./Dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import { message } from "antd";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PhoneIcon from "@mui/icons-material/Phone";

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);

  async function getDashboardCount() {
    try {
      const res = await axios.post(
        "/api/user/get-dashboard-count",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setCount(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getDashboardCount();
    }
  }, [user]);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">DASHBOARD</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div
            className="shadow dash-card"
            onClick={() => navigate("/my-listings")}
          >
            <div className="count">
              <span className="text-muted">
                <small>My Listings</small>
              </span>
              <h1 className="m-0">
                <b>{count === null ? "0" : count?.properties}</b>
              </h1>
            </div>
            <HomeWorkIcon className="icon" />
          </div>

          <div
            className="shadow dash-card"
            onClick={() => navigate("/contact-request")}
          >
            <div className="count">
              <span className="text-muted">
                <small>Contact Requests</small>
              </span>
              <h1 className="m-0">
                <b>{count === null ? "0" : count?.soldProduct}</b>
              </h1>
            </div>
            <PhoneIcon className="icon" />
          </div>

          <div
            className="shadow dash-card"
            onClick={() => navigate("/my-wishlist")}
          >
            <div className="count">
              <span className="text-muted">
                <small>My Wishlist</small>
              </span>
              <h1 className="m-0">
                <b>{count === null ? "0" : count?.wishlist}</b>
              </h1>
            </div>
            <FavoriteIcon className="icon" />
          </div>

          <div
            className="shadow dash-card"
            onClick={() => navigate("/my-account")}
          >
            <div className="count">
              <span className="text-muted">
                <small>My Profile</small>
              </span>
              <h1 className="m-0">
                <b>{count === null ? "0" : count?.properties}</b>
              </h1>
            </div>
            <HomeWorkIcon className="icon" />
          </div>

          <div
            className="shadow dash-card"
            onClick={() => navigate("/order-history")}
          >
            <div className="count">
              <span className="text-muted">
                <small>My Orders</small>
              </span>
              <h1 className="m-0">
                <b>{count === null ? "0" : count?.properties}</b>
              </h1>
            </div>
            <ShoppingCartIcon className="icon" />
          </div>

          <div
            className="shadow dash-card"
            onClick={() => navigate("/my-wishlist")}
          >
            <div className="count">
              <span className="text-muted">
                <small>Sold Ads</small>
              </span>
              <h1 className="m-0">
                <b>{count === null ? "0" : count?.soldProduct}</b>
              </h1>
            </div>
            <AssignmentLateIcon className="icon" />
          </div>

        </div>
        
      </DashboardLayout>
    </Layout>
  );
};

export default Dashboard;
