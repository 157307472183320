import React, { useEffect, useState } from "react";
import "./Announcement.css";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { message } from "antd";
import NoResultFound from "../components/UI/NoResultFound";

const Announcement = () => {
  const [ann, setAnn] = useState(null);
  const [singleAnn, setSingleAnn] = useState(null);
  const [tab, setTab] = useState(0);

  async function getAllAnn() {
    try {
      const res = await axios.get("/api/ann/get-all-ann");
      if (res.data.success) {
        setAnn(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllAnn();
  }, []);
  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">ANNOUNCEMENTS</h5>
          </div>
          <hr className="w-100 mt-0" />
          {tab === 0 ? (
            <div className="ann-container">
              {ann?.length === 0 ? (
                <NoResultFound />
              ) : (
                ann?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSingleAnn(item);
                        setTab(1);
                      }}
                      key={index}
                      className="ann"
                    >
                      <h5 className="m-0">{item?.title}</h5>
                      <div>
                        <span>
                          {new Date(item?.date).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </span>
                        {" | "}
                        <span>{item?.type}</span>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ) : (
            <div className="ann-container single-ann">
              <div style={{ cursor: "pointer" }} onClick={() => setTab(0)}>
                <ArrowBackIcon />
                Back
              </div>
              <div className="single-ann-content">
                {singleAnn?.image !== null && (
                  <img src={`annImages/${singleAnn?.image}`} alt="" />
                )}
                {singleAnn?.image !== null && <br />}
                <h4 className="mt-4">{singleAnn?.title}</h4>
                <p>{singleAnn?.desc}</p>
                <p className="mb-0 text-secondary">Type: {singleAnn?.type}</p>
                <p className="mb-0 text-secondary">
                  Contact: {singleAnn?.contact}
                </p>
                <p className="mb-0 text-secondary">
                  Source: {singleAnn?.source}
                </p>
              </div>
            </div>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Announcement;
