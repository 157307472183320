import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./AdminUsers.css";

const AdminProperties = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [properties, setProperties] = useState(null);
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  // delete product
  const handleDeleteProduct = async (id, images) => {
    const shouldDelete = window.confirm("Are you sure to delete?");
    if (shouldDelete) {
      try {
        const res = await axios.post(
          "/api/property/delete-property",
          {
            id,
            images,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          getAllProperties();
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // User clicked "Cancel" or closed the dialog
    }
  };
  // get all products
  const getAllProperties = async () => {
    try {
      const res = await axios.get("/api/admin/admin-get-all-properties", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setProperties(res.data.data);
        setData(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function filterProducts() {
    if (filter === "" || filter === null) {
      setProperties(data);
    } else {
      const isSold = filter === "sold";
      const filteredProducts = data?.filter((item) => {
        return item?.sold === isSold;
      });
      setProperties(filteredProducts);
    }
  }

  useEffect(() => {
    filterProducts();
  }, [filter]);

  useEffect(() => {
    if (params?.type) {
      setFilter(params?.type);
    }
  }, [params.type]);

  useEffect(() => {
    filterProducts();
  }, [filter, data]);

  useEffect(() => {
    getAllProperties();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Products</h3>
        </div>
        <hr />
        <div className="table-container">
          <div className="tools">
            <div className="form-fields w-100 my-3">
              <select
                name="type"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="form-select"
              >
                <option value="">Select</option>
                <option value="live">Live Ads</option>
                <option value="sold">Sold Ads</option>
              </select>
            </div>
          </div>
          <table className="table user-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Price</th>
                <th>Area</th>
                <th>Sold</th>
                <th>Verified</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {properties &&
                properties?.map((property, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img src={property?.images[0]} alt="" />
                      </td>
                      <td>
                        <small>{property?.title}</small>
                      </td>
                      <td>
                        <small>{property?.category}</small>
                      </td>
                      <td>
                        <small>{property?.subCategory}</small>
                      </td>
                      <td>
                        <small>{property?.price}</small>
                      </td>
                      <td>
                        <small>{property?.area}</small>
                      </td>
                      <td>
                        <small>{property?.sold ? "Yes" : "No"}</small>
                      </td>
                      <td>
                        <small>{property?.verified ? "Yes" : "No"}</small>
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <EditIcon
                            onClick={() =>
                              navigate(`/admin-view-product/${property?.bbId}`)
                            }
                            className="me-2 text-muted"
                          />
                          <DeleteIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleDeleteProduct(
                                property?._id,
                                property?.images
                              )
                            }
                            className="text-danger"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="pagination"></div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminProperties;
