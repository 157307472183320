import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Dashboard from "./user/Dashboard";
import Announcement from "./user/Announcement.js";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./admin/AdminDashboard";
import AdminUsers from "./admin/AdminUsers";
import EditUser from "./admin/EditUser";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import ProductInfo from "./pages/ProductInfo";
import Account from "./user/Account";
import AdminQueries from "./admin/AdminQueries";
import TermsAndConditions from "./pages/Terms";
import Subscribers from "./admin/Subscribers";
import AdminRoutes from "./components/AdminRoutes";
import ProductPage from "./pages/ProductPage";
import AddProperty from "./user/AddProperty";
import MyListings from "./user/MyListings";
import EditProperty from "./user/EditProperty";
import AdminProperties from "./admin/AdminProperties";
import AdminViewProperties from "./admin/AdminViewProperties";
import MyWishlist from "./user/MyWishlist";
import SellerProfile from "./pages/SellerProfile";
import ContactRequest from "./user/ContactRequest";
import OrderHistory from "./user/OrderHistory";
import Blog from "./pages/Blog";
import AdminBlog from "./admin/AdminBlog";
import AdminAnnouncements from "./admin/AdminAnnouncements";
import AdminDeals from "./admin/AdminDeals";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* pages */}
        <Route path="/" element={<Home />} />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/listing/:category?" element={<ProductPage />} />
        <Route path="/listing-info/:bbId?" element={<ProductInfo />} />
        <Route path="/faq" element={<FrequentlyAskedQuestions />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* ======================== USER PAGES =============================== */}
        {/* ========== EMAIL VERIFY */}
        <Route
          path="/add-product"
          element={
            <ProtectedRoute>
              <AddProperty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/seller-profile/:id?"
          element={
            <ProtectedRoute>
              <SellerProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-listing/:bbId?"
          element={
            <ProtectedRoute>
              <EditProperty />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-listings"
          element={
            <ProtectedRoute>
              <MyListings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact-request"
          element={
            <ProtectedRoute>
              <ContactRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-history"
          element={
            <ProtectedRoute>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-wishlist"
          element={
            <ProtectedRoute>
              <MyWishlist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/announcement"
          element={
            <ProtectedRoute>
              <Announcement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/* ======================== USER PAGES =============================== */}
        {/* ======================== ADMIN PAGES =============================== */}
        <Route
          path="/admin-dashboard"
          element={
            <AdminRoutes>
              <AdminDashboard />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-products/:type?"
          element={
            <AdminRoutes>
              <AdminProperties />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-view-product/:bbId?"
          element={
            <AdminRoutes>
              <AdminViewProperties />
            </AdminRoutes>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-user/:id?"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-queries"
          element={
            <ProtectedRoute>
              <AdminQueries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-subscribers"
          element={
            <ProtectedRoute>
              <Subscribers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-blog"
          element={
            <ProtectedRoute>
              <AdminBlog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-announcements"
          element={
            <ProtectedRoute>
              <AdminAnnouncements />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-deals"
          element={
            <ProtectedRoute>
              <AdminDeals />
            </ProtectedRoute>
          }
        />
        {/* ======================== ADMIN PAGES =============================== */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
