import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HistoryIcon from "@mui/icons-material/History";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useSelector } from "react-redux";
import defaultImage from "../../img/default.png";

const DashboardLayout = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [dbMenu, setDbMenu] = useState(false);
  const [dbBackdrop, setDbBackdrop] = useState(false);

  const handleLogout = () => {
    localStorage.clear("token");
    navigate("/login");
  };

  return (
    <div className="dashboard-container">
      <div
        className="mobile-menu d-block d-md-block d-lg-none"
        onClick={() => {
          setDbBackdrop(!dbBackdrop);
          {
            setDbMenu(!dbMenu);
            setDbBackdrop(!dbBackdrop);
          }
        }}
      >
        <DashboardIcon className="icon text-white" />
        <span>Dashboard Menu</span>
      </div>
      <div
        className={`db-menu-backdrop ${dbBackdrop && "active"}`}
        onClick={() => {
          setDbMenu(false);
          setDbBackdrop(false);
        }}
      ></div>
      <div
        className={`db-menu d-block d-md-block d-lg-none d-flex ${
          dbMenu && "active"
        }`}
      >
        <h6>
          <small>MAIN</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            <Link to="/user-dashboard">
              <DashboardIcon className="icon me-1" /> Dashboard
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/my-account" && "active"}`}
          >
            <Link to="/my-account">
              <AccountCircleIcon className="icon me-1" />
              My Account
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>LISTINGS</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/my-listings" && "active"}`}
          >
            <Link to="/my-listings">
              <FormatListNumberedRtlIcon className="icon me-1" />
              My Listings
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${
              location.pathname === "/contact-request" && "active"
            }`}
          >
            <Link to="/contact-request">
              <ConnectWithoutContactIcon className="icon me-1" />
              Contact Request
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/order-history" && "active"}`}
          >
            <Link to="/order-history">
              <HistoryIcon className="icon me-1" />
              Order History
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/my-wishlist" && "active"}`}
          >
            <Link to="/my-wishlist">
              <FavoriteIcon className="icon me-1" />
              My Wishlist
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/add-product" && "active"}`}
          >
            <Link to="/add-product">
              <AddBusinessIcon className="icon me-1" />
              Post Ads
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>OTHER</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/announcement" && "active"}`}
          >
            <Link to="/announcement">
              <CircleNotificationsIcon className="icon me-1" />
              Announcements
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/listing">
              <AdsClickIcon className="icon me-1" />
              Ads List
            </Link>
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <LogoutIcon className="icon me-1" />
            Logout
          </li>
        </ul>
      </div>

      {/* DASHBOARD VIEW  */}
      <div className="dashboard-menu d-none d-md-none d-lg-block">
        <div className="dash-profile">
          <img className="dash-img" src={user?.image || defaultImage} alt="" />
          <div className="pro-content">
            <h5 className="m-0">{user?.username}</h5>
            <span>
              <small>ID: {user?.profileId}</small>
            </span>
          </div>
        </div>
        <h6>
          <small>MAIN</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            <Link to="/user-dashboard">
              <DashboardIcon className="icon me-1" /> Dashboard
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/my-account" && "active"}`}
          >
            <Link to="/my-account">
              <AccountCircleIcon className="icon me-1" />
              My Account
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>LISTINGS</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/my-listings" && "active"}`}
          >
            <Link to="/my-listings">
              <FormatListNumberedRtlIcon className="icon me-1" />
              My Listings
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${
              location.pathname === "/contact-request" && "active"
            }`}
          >
            <Link to="/contact-request">
              <ConnectWithoutContactIcon className="icon me-1" />
              Contact Request
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/order-history" && "active"}`}
          >
            <Link to="/order-history">
              <HistoryIcon className="icon me-1" />
              Order History
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/my-wishlist" && "active"}`}
          >
            <Link to="/my-wishlist">
              <FavoriteIcon className="icon me-1" />
              My Wishlist
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/add-product" && "active"}`}
          >
            <Link to="/add-product">
              <AddBusinessIcon className="icon me-1" />
              Post Ad
            </Link>
          </li>
        </ul>
        <h6 className="mt-4">
          <small>OTHER</small>
        </h6>
        <hr />
        <ul>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/announcement" && "active"}`}
          >
            <Link to="/announcement">
              <CircleNotificationsIcon className="icon me-1" />
              Announcements
            </Link>
          </li>
          <li
            onClick={() => {
              setDbMenu(!dbMenu);
              setDbBackdrop(!dbBackdrop);
            }}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <Link to="/listing">
              <AdsClickIcon className="icon me-1" />
              Ads List
            </Link>
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            <LogoutIcon className="icon me-1" />
            Logout
          </li>
        </ul>
      </div>
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
