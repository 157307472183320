import React, { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactPaginate from "react-paginate";
import "./Blog.css";

const Blog = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState(null);
  const [singleBlog, setSingleBlog] = useState(null);
  const [tab, setTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10; // You can adjust this as per your requirement

  const getAllBlogs = async () => {
    try {
      const res = await axios.get("/api/blog/get-all-blog");
      if (res.data.success) {
        setBlogs(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const displayedBlogs = blogs?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <Layout breadcrumb={true} title={"Blog"}>
      {tab === 0 ? (
        <div className="blogs-container">
          {displayedBlogs?.map((item, index) => {
            return (
              <div
                className="blog"
                key={index}
                onClick={() => {
                  setSingleBlog(item);
                  setTab(1);
                }}
              >
                <div className="blog-img">
                  <img src={`blogImages/${item?.image}`} alt="" />
                </div>
                <div className="blog-content">
                  <h4>{item?.title}</h4>
                  <p>{item?.description}</p>
                  <div className="m-0">
                    <span>Author: {item?.author}</span>
                    <span>
                      {new Date(item?.date).toLocaleString("default", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(blogs?.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <div className="single-blog">
          <div className="s-blog">
            <div style={{ cursor: "pointer" }} onClick={() => setTab(0)}>
              <ArrowBackIcon />
              Back
            </div>
            <img src={`blogImages/${singleBlog?.image}`} alt="" />
            <h2>{singleBlog?.title}</h2>
            <div>
              <span>
                <small>Author: {singleBlog?.author}</small>
              </span>
              <span>
                <small>
                  {" "}
                  |{" "}
                  {new Date(singleBlog?.date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </small>
              </span>
              <p>{singleBlog?.description}</p>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Blog;