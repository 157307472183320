import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import HelpIcon from "@mui/icons-material/Help";
import BookIcon from "@mui/icons-material/Book";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CampaignIcon from "@mui/icons-material/Campaign";

import { Link } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar-container">
      <span>
        <small>MAIN</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="icon me-2" />
            Dashboard
          </Link>
        </li>
      </ul>
      <span>
        <small>LISTS</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-products">
            <InventoryIcon className="icon me-2" />
            Products
          </Link>
        </li>
        <li>
          <Link to="/admin-users">
            <GroupIcon className="icon me-2" />
            Customers
          </Link>
        </li>
        <li>
          <Link to="/admin-deals">
            <HandshakeIcon className="icon me-2" />
            Deals
          </Link>
        </li>
        <li>
          <Link to="/admin-blog">
            <BookIcon className="icon me-2" />
            Add Blog
          </Link>
        </li>
        <li>
          <Link to="/admin-queries">
            <HelpIcon className="icon me-2" />
            Queries
          </Link>
        </li>
        <li>
          <Link to="/admin-subscribers">
            <SupervisedUserCircleIcon className="icon me-2" />
            Subcribers
          </Link>
        </li>
        <li>
          <Link to="/admin-announcements">
            <CampaignIcon className="icon me-2" />
            Announcement
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
