import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./ProductInfo.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import axios from "axios";
import VerifiedIcon from "@mui/icons-material/Verified";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import { lightGreen } from "@mui/material/colors";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import SocialShareButton from "../components/SocialShareButton";
import Slider from "react-slick";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [property, setProperty] = useState(null);
  const [image, setImage] = useState(0);
  const [wishlist, setWishlist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wishLoader, setWishLoader] = useState(false);
  const [seller, setSeller] = useState(null);
  const [carousel, setCarousel] = useState(false);

  const [error, setError] = useState(false);
  const [err, setErr] = useState(false);

  const [showContact, setShowContact] = useState(false);

  const shareUrl = `https://uniexchange.in/listing-info/${params.bbId}`;

  //! ADD DEAL
  async function handleAddDeal() {
    try {
      if (!user) {
        return message.error("Please login first!");
      }
      const dealObject = {
        bbId: property?.bbId,
        user: user?.username,
        userEmail: user?.email,
        userMobile: user?.mobile,
        seller: seller?.username,
        sellerEmail: seller?.email,
        sellerMobile: seller?.mobile,
        sellerProfileId: seller?.profileId,
        product: property?.title,
        productImg: property?.images[0],
        access: true,  //Approving contact access by default
      };
      const res = await axios.post("/api/deal/add-deal", dealObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setShowContact(true);
      } else {
        message.error(res.data.message);
        setShowContact(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //! GET PRODUCT
  async function getProperty() {
    try {
      const res = await axios.post(
        "/api/property/get-property",
        {
          bbId: params?.bbId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProperty(res.data.data);
        console.log(res.data.data.email);
        getSeller(res.data.data.email);
        checkWishlist();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //! GET USER
  async function getSeller(email, profileId) {
    try {
      const res = await axios.post(
        "/api/user/get-seller",
        {
          email: email || property?.email,
          profileId: profileId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setSeller(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //! ADD TO WISHLIST
  async function handleAddToWishlist() {
    if (!user) {
      message.error("Please Login First");
      return;
    }
    try {
      setWishLoader(true);
      const propertyObject = {
        email: user?.email,
        bbId: property.bbId,
        image: property?.images[0],
        title: property.title,
        about: property.about,
        price: property.price,
        bedroom: property.bedroom,
        type: property.type,
        category: property.category,
        area: property.area,
        builtupArea: property.builtupArea,
        plotArea: property.plotArea,
      };
      const res = await axios.post("/api/user/add-to-wishlist", propertyObject);
      if (res.data.success) {
        setWishlist(true);
        setWishLoader(false);
      } else {
        setWishLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setWishLoader(false);
      console.log(error);
    }
  }

  //! REMOVE FROM WISHLIST
  async function handleRemoveWishlist() {
    if (!user) {
      message.error("Please Login First");
      return;
    }
    try {
      setWishLoader(true);
      const propertyObject = {
        email: user?.email,
        bbId: property.bbId,
      };
      const res = await axios.post(
        "/api/user/remove-from-wishlist",
        propertyObject
      );
      if (res.data.success) {
        setWishlist(false);
        setWishLoader(false);
      } else {
        setWishLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setWishLoader(false);
    }
  }

  //! CHECK WISHLIST
  function checkWishlist() {
    if (user && user.wishlist && params?.bbId) {
      const isPresent = user.wishlist.some((item) => item.bbId === params.bbId);
      setWishlist(isPresent);
    } else {
      setWishlist(false);
    }
  }

  useEffect(() => {
    checkWishlist();
  }, [user]);

  useEffect(() => {
    getProperty();
  }, [params?.bbId]);

  const arrowRef = useRef();

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: false,
    prevArrow: false,
  };

  return (
    // <Layout breadcrumb={true} title={`${property?.title}`}>
    <Layout>
      {carousel && (
        <div className="black-img-slider">
          <div className="screen">
            <div onClick={() => setCarousel(false)} className="close-icon">
              <CancelIcon className="icon" />
            </div>
            <Slider ref={arrowRef} {...settings}>
              {property?.images?.map((item, index) => {
                return (
                  <img
                    className="c-img"
                    src={property && property?.images[index]}
                    alt=""
                  />
                );
              })}
            </Slider>
            <div className="arrow-container screen-arrows">
              <div onClick={() => arrowRef.current.slickPrev()}>
                <ArrowBackIosNewSharpIcon className="icon screen-slider-left" />
              </div>
              <div onClick={() => arrowRef.current.slickNext()}>
                <ArrowForwardIosSharpIcon className="icon screen-slider-right" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="product-info-container">
        <div className="product-info-container-left">
          {/* PRODUCT TAGS  */}
          <div className="product-tags">
            <div className="tags">
              <span>
                <VerifiedIcon className="icon" />
              </span>
              <span className="ms-1 text-success">
                <small>Verified Seller</small>
              </span>
            </div>
          </div>
          {/* TITLE  */}
          <h4>{property?.title}</h4>
          {/* LOCAITION TIME DATE */}
          <div className="time-date-location">
            <div className="time">
              <span>
                <FmdGoodIcon className="icon" />
              </span>
              <span className="ms-1">
                <small>{property?.area}</small>
              </span>
            </div>
            <div className="time">
              <span>
                <AccessTimeIcon className="icon" />
              </span>
              <span className="ms-1">
                <small>
                  {new Date(property?.created).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </small>
              </span>
            </div>
          </div>
          <img
            onClick={() => setCarousel(true)}
            className="main-img"
            src={property && property?.images[image]}
            alt=""
          />
          <div className="img-slider">
            {property?.images?.map((item, index) => {
              return (
                <img
                  key={index}
                  onClick={() => setImage(index)}
                  className={`${index === image && "active"}`}
                  src={item}
                  alt="image"
                />
              );
            })}
          </div>
          <div className="product-about my-4">
            <hr />
            <h6 className="fw-bold">Description</h6>
            <p>{property?.about}</p>
          </div>
          <hr />
          <div className="product-features">
            {property?.brand !== "" && property?.brand !== null && (
              <span>
                <p className="m-0">
                  <b>Brand:</b> {property?.brand}
                </p>
              </span>
            )}
            {property?.condition !== "" && property?.condition !== null && (
              <span>
                <p className="m-0">
                  <b>Condition:</b> {property?.condition}
                </p>
              </span>
            )}
            {property?.bikeKm !== "" && property?.bikeKm !== null && (
              <span>
                <p className="m-0">
                  <b>KM:</b> {property?.bikeKm}
                </p>
              </span>
            )}
            {property?.bikeYear !== "" && property?.bikeYear !== null && (
              <span>
                <p className="m-0">
                  <b>Year:</b> {property?.bikeYear}
                </p>
              </span>
            )}
            {property?.size !== "" && property?.size !== null && (
              <span>
                <p className="m-0">
                  <b>Size:</b> {property?.size}
                </p>
              </span>
            )}
            {property?.color !== "" && property?.color !== null && (
              <span>
                <p className="m-0">
                  <b>Color:</b> {property?.color}
                </p>
              </span>
            )}
            {property?.author !== "" && property?.author !== null && (
              <span>
                <p className="m-0">
                  <b>Author:</b> {property?.author}
                </p>
              </span>
            )}
            {property?.edition !== "" && property?.edition !== null && (
              <span>
                <p className="m-0">
                  <b>Edition:</b> {property?.edition}
                </p>
              </span>
            )}
          </div>
          <hr />
          <h6 className="fw-bold">Features</h6>
          <div className="product-features">
            {property?.features?.map((item, index) => {
              return (
                <span>
                  <DoneIcon className="icon me-1" />
                  {item?.name}
                </span>
              );
            })}
          </div>
        </div>
        <div className="product-info-container-right form-sticky">
          <div className="d-none d-lg-block">
            <div className="product-price d-flex">
              <h4 className="m-0">
                <b>
                  ₹{property?.price} {property?.type === "Rent" && "Monthly"}
                </b>
              </h4>
              <div>
                {/* <ShareIcon /> */}
                {wishlist ? (
                  wishLoader ? (
                    <div class="spinner-grow text-danger" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <FavoriteIcon
                      onClick={handleRemoveWishlist}
                      className="icon text-danger"
                    />
                  )
                ) : wishLoader ? (
                  <div class="spinner-grow text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <FavoriteBorderIcon
                    className="icon"
                    onClick={handleAddToWishlist}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="product-contact">
            <h5>Contact Seller</h5>
            {seller && (
              <div className="seller-info">
                <div className="d-flex">
                  <img className="me-2" src={seller?.image} alt="" />
                  <div>
                    <p className="m-0">Ad by:</p>
                    <h6 className="m-0">{seller?.username}</h6>
                    {showContact && <h6 className="m-0">Mob: {seller?.mobile}</h6>} 
                    
                  </div>
                </div>
                <div>
                  <span
                    role="button"
                    className="text-primary"
                    onClick={() =>
                      !user
                        ? message.error("Please login first")
                        : navigate(`/seller-profile/${seller?.profileId}`)
                    }
                  >
                    view Profile
                  </span>
                </div>
              </div>
            )}
            {user?.email !== property?.email && (
              <button onClick={handleAddDeal} className="bb-btn mt-3">
                Request Contact
              </button>
            )}
          </div>

          <div className="d-none d-lg-block d-flex share-buttons-container">
            <h6>Share Ads</h6>
            <div className="share-buttons">
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={40} />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={40} />
              </LinkedinShareButton>
              <PinterestShareButton url={shareUrl}>
                <PinterestIcon size={40} />
              </PinterestShareButton>
              <TelegramShareButton url={shareUrl}>
                <TelegramIcon size={40} />
              </TelegramShareButton>
            </div>
          </div>

          <div className="product-price-footer d-block d-md-none d-lg-none d-flex">
            <div>
              <span>
                <small>Product Price </small>
                <b>₹{property?.price}</b>
              </span>
              <h6>
              </h6>
            </div>
            <div>
              {/* <ShareIcon /> */}
              {wishlist ? (
                wishLoader ? (
                  <div class="spinner-grow text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <FavoriteIcon
                    onClick={handleRemoveWishlist}
                    className="icon text-danger"
                  />
                )
              ) : wishLoader ? (
                <div class="spinner-grow text-danger" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <FavoriteBorderIcon
                  className="icon"
                  onClick={handleAddToWishlist}
                />
              )}
            </div>
          </div>
        </div>
        <SocialShareButton shareUrl={shareUrl} />
      </div>
    </Layout>
  );
};

export default ProductInfo;
