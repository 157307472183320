import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import NoResultFound from "../components/UI/NoResultFound";
import "../pages/Contact.css";

const ContactRequest = () => {
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState(null);
  const [deals, setDeals] = useState(null);
  const [tab, setTab] = useState(0);

  async function getUserDeals() {
    try {
      const res = await axios.post(
        "/api/deal/get-user-deals",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setDeals(res.data.data);
        setData(res.data.data.reverse());
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGiveAcess(id) {
    try {
      const res = await axios.post(
        "/api/deal/give-access",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getUserDeals();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function filterRequests(val) {
    if (val === "All") {
      setDeals(data);
    } else if (val === "Sent") {
      const filterDeals = data?.filter(
        (item) => item?.userEmail === user?.email
      );
      setDeals(filterDeals);
    } else if (val === "Received") {
      const filterDeals = data?.filter(
        (item) => item?.userEmail !== user?.email
      );
      setDeals(filterDeals);
    }
  }

  useEffect(() => {
    if (user) {
      getUserDeals();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">CONTACT REQUESTS</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div>
            <button
              onClick={() => {
                setTab(0);
                filterRequests("All");
              }}
              className={`bb-btn ${tab === 0 && "opacity"}`}
            >
              All
            </button>
            <button
              onClick={() => {
                setTab(1);
                filterRequests("Sent");
              }}
              className={`bb-btn ${tab === 1 && "opacity"} mx-2`}
            >
              Sent
            </button>
            <button
              onClick={() => {
                setTab(2);
                filterRequests("Received");
              }}
              className={`bb-btn ${tab === 2 && "opacity"}`}
            >
              Received
            </button>
          </div>
          <table className="table contact-req-table">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Name</th>
                {/* <th>University ID</th> */}
                {/* <th>Email</th> */}
                <th>Mobile</th>
                <th>Product</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {deals?.length === undefined ? (
                <tr>
                  <td colSpan={100}>
                    <NoResultFound />
                  </td>
                </tr>
              ) : deals.length === 0 ? (
                <tr>
                  <td colSpan={100}>
                    <NoResultFound />
                  </td>
                </tr>
              ) : (
                deals?.map((item, index) => {
                  return (
                    <tr className={`${item?.user === "Outside" && "d-none"}`}>
                      <td data-title="Srno">{index + 1}</td>
                      <td data-title="Name">
                        {user?.email === item?.sellerEmail && item?.user}
                        {user?.email !== item?.sellerEmail && item?.seller}
                      </td>
                      {/* <td data-title="Email">
                        {user?.email === item?.sellerEmail && item?.userEmail}
                        {user?.email !== item?.sellerEmail && item?.access
                          ? item?.sellerEmail
                          : user?.email !== item?.sellerEmail && "--"}
                      </td> */}
                      <td data-title="Mobile">
                        {user?.email === item?.sellerEmail && item?.userMobile}
                        {user?.email !== item?.sellerEmail && item?.access
                          ? item?.sellerMobile
                          : user?.email !== item?.sellerEmail && "--"}
                      </td>
                      <td data-title="Product">
                        <small>{item?.product}</small>
                      </td>
                      <td data-title="Action">
                        {user?.email === item?.sellerEmail &&
                          (item?.access ? (
                            "Approved"
                          ) : (
                            <button
                              className="bb-btn p-0 p-1 px-2"
                              onClick={() => handleGiveAcess(item?._id)}
                            >
                              Give Access
                            </button>
                          ))}
                        {user?.email !== item?.sellerEmail
                          ? item?.access
                            ? "Approved"
                            : "Waiting for approval"
                          : ""}
                      </td>
                    </tr>
                  );
                })
              )}
              <tr></tr>
            </tbody>
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default ContactRequest;
