import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import "./AddProperty.css";
import InfoIcon from "@mui/icons-material/Info";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { message } from "antd";
import axios from "axios";
import Areas from "../Areas";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import imageCompression from "browser-image-compression";
import category from "../category/categoryMenu";

const EditProperty = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(form?.images || []);

  const getProperty = async (e) => {
    try {
      const res = await axios.post("/api/property/get-property-edit", {
        bbId: params.bbId,
        address: true,
      });
      if (res.data.success) {
        setForm(res.data.data);
        setSelectedFiles(res.data.data.images);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProperty();
  }, []);

  const handleFeatureChange = (e, index) => {
    const { value } = e.target;
    setForm((prevForm) => {
      const updatedFeatures = [...prevForm.features];
      updatedFeatures[index] = { name: value };
      return { ...prevForm, features: updatedFeatures };
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (name === "area") {
      const isValidArea = Areas?.data?.some((item) => item.name === value);
      if (isValidArea) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const handleFileChange = async (e) => {
    const files = fileInputRef.current.files;
    if (!files) {
      // Handle the case when files are not defined
      return;
    }
    if (files.length > 8) {
      alert("You can only upload up to 8 files.");
      e.target.value = null;
      return;
    }
    // Compress each selected image before updating the state
    const compressedFiles = await Promise.all(
      Array.from(files).map(async (file) => {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          return compressedFile;
        } catch (error) {
          console.error("Error compressing image:", error);
          return file; // If compression fails, use the original file
        }
      })
    );
    // Update selectedFiles state with the compressed files
    setSelectedFiles([...selectedFiles, ...compressedFiles]);
  };

  const handleDeleteAllPropertyImages = async (id) => {
    try {
      const res = await axios.post(
        "/api/property/delete-property-images",
        {
          id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getProperty();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const featuresString = JSON.stringify(form.features);
    formData.append("bbId", form?.bbId);
    formData.append("title", form?.title);
    formData.append("about", form?.about);
    formData.append("category", form?.category);
    formData.append("subCategory", form?.subCategory);
    formData.append("condition", form?.condition);
    formData.append("price", form?.price);
    formData.append("area", form?.area);
    formData.append("address", form?.address);
    formData.append("email", form?.email);
    formData.append("mobile", form?.mobile);
    formData.append("features", featuresString);
    // EXTRA FIELDS
    formData.append("brand", form?.brand);
    formData.append("mType", form?.mType);
    formData.append("bikeKm", form?.bikeKm);
    formData.append("bikeYear", form?.bikeYear);
    formData.append("size", form?.size);
    formData.append("color", form?.color);
    formData.append("author", form?.author);
    formData.append("edition", form?.edition);

    const filesArray = Array.from(selectedFiles);
    if (selectedFiles.length === 0) {
      message.error("No photo is selected");
      return;
    }
    // Append images
    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      formData.append("images", file);
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/property/update-property", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        // getProperty();
        // fileInputRef.current.value = null;
        navigate("/my-listings");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const handleAddFeatures = () => {
    setForm((prevForm) => ({
      ...prevForm,
      features: [...prevForm.features, { name: "" }],
    }));
  };

  const handleRemoveFeature = (index) => {
    setForm((prevForm) => {
      const updatedFeatures = [...prevForm.features];
      updatedFeatures.splice(index, 1);
      return { ...prevForm, features: updatedFeatures };
    });
  };

  const subCategory = category?.filter(
    (item) => item.name === form?.category
  )[0]?.sub;

  const brands = subCategory?.filter(
    (item) => item?.name === form?.subCategory
  )[0]?.brand;

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">EDIT PRODUCT</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div className="basic-info-property">
            <small className="text-danger">
              Note: This Product will go under verification process if you make
              any changes!!{" "}
            </small>
          </div>
          <div className="basic-info-property">
            <div>
              <CollectionsIcon className="me-1" />
              Product Images
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <label htmlFor="" className="form-lable mb-1">
                  <small>You can upload upto 8 images</small>
                </label>
                <input
                  aria-label="Select Image"
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  name="image"
                  required
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="form-control"
                />
                <small className="text-danger">
                  Note: Images size should be less than 1 MB
                </small>
                <div className="thumb-img">
                  {(form?.images || []).map((item, index) => (
                    <img key={index} src={item} alt={`img-${index}`} />
                  ))}
                </div>

                <button
                  onClick={() => handleDeleteAllPropertyImages(form?._id)}
                  className="bg-danger bb-btn mt-3"
                >
                  Delete All Images
                </button>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <InfoIcon className="me-1" />
              Basic Information
            </div>
            <hr />
            <div className="row">
              <div className="col-12 form-fields mb-2">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  value={form?.title}
                  onChange={handleChange}
                  placeholder="Enter product title"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Category</small>
                  </label>
                  <select
                    name="category"
                    className="form-select"
                    value={form?.category}
                    onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    {category?.map((item, index) => {
                      return <option value={item?.name}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Sub Category</small>
                  </label>
                  <select
                    name="subCategory"
                    className="form-select"
                    value={form?.subCategory}
                    onChange={handleChange}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategory?.map((item, index) => {
                      return (
                        <option key={index} value={item?.name}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* EXTRA FIELDS */}
              {/* MOBILE  */}
              {form?.category === "Mobile & Tablets" &&
                form?.subCategory === "Accessories" && (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Type</small>
                      </label>
                      <select
                        name="mType"
                        value={form?.mType}
                        onChange={handleChange}
                        className="form-select"
                      >
                        <option value="">Select Type</option>
                        <option value="Mobile">Mobile</option>
                        <option value="Tablet">Tablet</option>
                      </select>
                    </div>
                  </div>
                )}
              {/* BIKE  */}
              {(form?.subCategory === "Motorcycles" ||
                form?.subCategory === "Scooters") && (
                <>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Bike KM</small>
                      </label>
                      <input
                        type="text"
                        name="bikeKm"
                        value={form?.bikeKm}
                        onChange={handleChange}
                        placeholder="Km driven"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Bike Year</small>
                      </label>
                      <input
                        name="bikeYear"
                        type="text"
                        value={form?.bikeYear}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Year of purchase"
                      />
                    </div>
                  </div>
                </>
              )}
              {/* BOOKS  */}
              {form?.category === "Books" && (
                <>
                  {form?.subCategory === "Academics" && (
                    <>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-fields mb-2">
                          <label htmlFor="" className="form-lable mb-1">
                            <small>Semester</small>
                          </label>
                          <input
                            type="text"
                            name="sem"
                            value={form?.sem}
                            onChange={handleChange}
                            placeholder="Enter Semester"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-fields mb-2">
                          <label htmlFor="" className="form-lable mb-1">
                            <small>Course Code</small>
                          </label>
                          <input
                            type="text"
                            name="courseCode"
                            value={form?.courseCode}
                            onChange={handleChange}
                            placeholder="Enter Course Code"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {form?.subCategory !== "Stationery" && (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Author</small>
                        </label>
                        <input
                          type="text"
                          name="author"
                          value={form?.author}
                          onChange={handleChange}
                          placeholder="Enter author name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Edition</small>
                      </label>
                      <input
                        type="text"
                        name="edition"
                        value={form?.edition}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter edition"
                      />
                    </div>
                  </div>
                </>
              )}

              {/* BRAND  */}
              {form?.category !== "Electronics & Appliances" &&
                form?.category !== "Fashion" &&
                form?.category !== "Books" &&
                form?.category !== "Events & Tickets" &&
                form?.category !== "Sports & Hobbies" &&
                form?.category !== "Accessories" &&
                form?.category !== "Household" &&
                form?.category !== "Services" &&
                form?.subCategory !== "Spare Parts" &&
                form?.subCategory !== "Accessories" && (
                  <div className="col-12 col-md-6 col-lg-6 form-fields mb-2">
                    <label htmlFor="">
                      <small>Brand</small>
                    </label>
                    <select
                      onChange={handleChange}
                      className="form-select"
                      name="brand"
                    >
                      <option value="">Select Brand</option>
                      {brands?.map((item, index) => {
                        return <option value={item?.name}>{item?.name}</option>;
                      })}
                    </select>
                  </div>
                )}

              {/* CONDITION  */}
              {form?.category !== "Services" && (
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Condition</small>
                    </label>
                    <select
                      name="condition"
                      className="form-select"
                      value={form?.condition}
                      onChange={handleChange}
                    >
                      <option value="">Select Condition</option>
                      <option value="New">New</option>
                      <option value="Used">Used</option>
                    </select>
                  </div>
                </div>
              )}
              {/* PRICE  */}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Price</small>
                  </label>
                  <input
                    name="price"
                    placeholder="Enter price"
                    type="text"
                    className="form-control"
                    value={form?.price}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* DESC  */}
              <div className="mt-2 col-12 form-fields mb-2">
                <textarea
                  name="about"
                  cols="30"
                  rows="5"
                  placeholder="Write something about your property"
                  className="form-control"
                  value={form?.about}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <EditLocationAltIcon className="me-1" />
              Location / Contacts
            </div>
            <hr />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Area</small>
                  </label>
                  <input
                    type="text"
                    list="areas"
                    name="area"
                    id="area"
                    value={form?.area}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Select Area"
                  />
                  <datalist id="areas" placeholder="">
                    {Areas?.data?.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </datalist>
                  <span className="text-danger">
                    <small>{error && "Select from the list only"}</small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-8">
                <div className="form-fields mb-2">
                  <label htmlFor="" className="form-lable mb-1">
                    <small>Address</small>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter full address"
                    className="form-control"
                    value={form?.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info-property">
            <div>
              <HomeWorkIcon className="me-1" />
              Product Features{" "}
              <button className="bb-btn" onClick={handleAddFeatures}>
                Add
              </button>
            </div>
            <hr />
            <div className="row">
              {form?.features?.map((item, index) => {
                return (
                  <div
                    className="col-12 col-sm-12 col-md-4 col-lg-6"
                    key={index}
                  >
                    <div className="form-fields mb-2 d-flex">
                      <input
                        type="text"
                        name={`features[${index}].name`}
                        placeholder="Product feature"
                        className="form-control"
                        value={item.name}
                        onChange={(e) => handleFeatureChange(e, index)}
                      />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm ms-2"
                        onClick={() => handleRemoveFeature(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <button
              disabled={error}
              onClick={handleSubmit}
              className="bb-btn w-100 mt-3"
            >
              Update Property
            </button>
          </div>
          <div className="contact-us">
            <span>
              Facing Problem while posting a property? Contact our team support{" "}
              <Link
                target="_blank"
                className="text-decoration-underline"
                to="https://wa.me/919284263003"
              >
                Click Here
              </Link>
            </span>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default EditProperty;
