import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { message } from "antd";
import axios from "axios";
import "./Contact.css";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

const Contact = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    msg: "",
  });
  const [error, setError] = useState(false);
  const [mapLoader, setMapLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setMapLoader(false);
    }, 1500);
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      form?.name === "" ||
      form?.email === "" ||
      form?.subject === "" ||
      form?.msg === ""
    ) {
      setError(true);
      return;
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", subject: "", msg: "" });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
    {/* <Layout breadcrumb={true} title={"Contact Us"}> */}
      <div className="contact-page-container">
        <div className="shd row">
          <div className="order-2 order-lg-1 col-12 col-sm-12 col-md-6 col-lg-6 contact-details">
            <h3>Contact Info</h3>
            <div className="c-details">
              <PhoneInTalkIcon className="icon" />
              <p className="m-0">+91 9883084820</p>
            </div>
            <div className="c-details">
              <EmailIcon className="icon" />
              <p className="m-0">uniexchangeofficial@gmail.com</p>
            </div>
            <div className="c-details">
              <LocationOnIcon className="icon" />
              <p className="m-0">Phagwara, Punjab, 144411</p>
            </div>
          </div>
          <div className="order-1 order-lg-2 col-12 col-sm-12 col-md-6 col-lg-6 contact-form">
            <h3>Send Message</h3>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="name">
                      Name
                    </label>
                    <input
                      onChange={handleChange}
                      name="name"
                      className="form-control"
                      type="text"
                      value={form.name}
                      placeholder="Enter your name"
                    />
                    <span className="text-danger">
                      <small>
                        {error && form?.name === "" && "Please enter your name"}
                      </small>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="name">
                      Email
                    </label>
                    <input
                      onChange={handleChange}
                      name="email"
                      className="form-control"
                      type="email"
                      value={form.email}
                      placeholder="Enter your email"
                    />
                    <span className="text-danger">
                      <small>
                        {error &&
                          form?.email === "" &&
                          "Please enter your email"}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Subject
                </label>
                <input
                  onChange={handleChange}
                  name="subject"
                  className="form-control"
                  type="text"
                  value={form.subject}
                  placeholder="Enter subject"
                />
                <span className="text-danger">
                  <small>
                    {error && form?.subject === "" && "Please enter subject"}
                  </small>
                </span>
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Message
                </label>
                <textarea
                  onChange={handleChange}
                  className="form-control"
                  value={form.msg}
                  name="msg"
                  rows="4"
                  placeholder="How can we help you?"
                ></textarea>
                <span className="text-danger">
                  <small>
                    {error && form?.msg === "" && "Please enter your message"}
                  </small>
                </span>
              </div>
              <button type="submit" className="register-btn m-0">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* MAP  */}
      <div className={`google-map loading ${mapLoader && "active"}`}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109137.22676530783!2d75.59587614908092!3d31.261201165298846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5f68025069f3%3A0xb2a61904d4ab7fc6!2sKhajurla%2C%20Punjab%20144411!5e0!3m2!1sen!2sin!4v1710871513577!5m2!1sen!2sin"
          width="100%"
          style={{ border: "0px" }}
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* <div className="container-fluid contact-address-email">
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="https://maps.app.goo.gl/zDLdDvE8nHY6M8g99"
          style={{ textDecoration: "none", color: "black" }}
        >
          <LocationOnIcon className="icon" style={{ fontSize: "45px" }} />
          <p>
            <b>Bhiwandi, Maharashtra, India</b>
          </p>
        </a>
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="mailto:bhiwandibroker@gmail.com"
          style={{ textDecoration: "none", color: "black" }}
        >
          <EmailIcon className="icon" style={{ fontSize: "45px" }} />
          <p>
            <b>bhiwandibroker@gmail.com</b>
          </p>
        </a>
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="https://www.instagram.com/bhiwandibroker/"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Instagram className="icon" style={{ fontSize: "45px" }} />
          <p>
            <b>Instagram</b>
          </p>
        </a>
      </div> */}
    </Layout>
  );
};

export default Contact;
