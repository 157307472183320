import React from "react";
import InboxIcon from "@mui/icons-material/Inbox";
import GroupIcon from "@mui/icons-material/Group";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Areas from "../../Areas";
import "./HowItWorks.css";

const HowItWorks = ({ count }) => {
  return (
    <div
      className="container-fluid how-it-works area"
      style={{ borderTopLeftRadius: "3px", borderTopRightRadius: "3px" }}
    >
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container before-footer">
        <div className="row">
          <div className="d-flex justify-content-center col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-5 mb-md-0 mb-lg-0 stats">
            <InboxIcon className="icon" />
            <div>
              <h2 className="m-0">{count?.productCount}+</h2>
              <span>
                <small>Published Ads</small>
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-5 mb-md-0 mb-lg-0 stats">
            <GroupIcon className="icon" />
            <div>
              <h2 className="m-0">{count?.userCount}+</h2>
              <span>
                <small>Verified Users</small>
              </span>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-0 mb-md-0 mb-lg-0">
            <HandshakeIcon className="icon" />
            <div>
              <h2 className="m-0">{count?.dealsCount+1}+</h2>
              <span>
                <small>Total Deals</small>
              </span>
            </div>
          </div> */}
          <div className="d-flex justify-content-center col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-0 mb-md-0 mb-lg-0 stats">
            <FmdGoodIcon className="icon" />
            <div>
              <h2 className="m-0">{Areas?.data?.length}+</h2>
              <span>
                <small>Locations</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
