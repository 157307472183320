import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye and eye-slash icons

import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [err, setErr] = useState(false);
  const [errr, setErrr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [otpField, setOtpField] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [form, setForm] = useState({
    profileId: "",
    uniID: "",
    username: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });
  const [count, setCount] = useState(30);
  const [resend, setResend] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);

  const togglePasswordVisibility = (val) => {
    if (val === "one") {
      setShowPassword(!showPassword);
    }
    if (val === "two") {
      setShowPasswordTwo(!showPasswordTwo);
    }
  };

  function generateRandomId() {
    const prefix = "PR";
    const numbers = Array.from({ length: 10 }, () =>
      Math.floor(Math.random() * 10)
    ).join("");
    setForm((prevForm) => ({
      ...prevForm,
      profileId: prefix + numbers,
    }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (name === "mobile") {
      if (isNaN(value)) {
        setErr(true);
      } else if (value.length > 10) {
        setErr(true);
      } else if (value.length < 10) {
        setErr(true);
      } else {
        setErr(false);
      }
    }
    if (name === "uniID") {
      if (isNaN(value)) {
        setErrr(true);
      } else if (value.length > 8) {
        setErrr(true);
      } else if (value.length < 8) {
        setErrr(true);
      } else {
        setErrr(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (enteredOtp.trim() === "") {
      return message.error("Please Enter 6 digits OTP");
    }
    if (enteredOtp !== otp) {
      return message.error("Incorrect OTP");
    }
    try {
      setVerifyLoading(false);
      setLoading(true);
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/login");
        setLoading(false);
        setVerifyLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  async function sendOtp(e = null) {
    if (e) {
      e.preventDefault(); // Only call preventDefault if event object is provided
    }
    if (
      form?.username === "" ||
      form?.email === "" ||
      form?.mobile === "" ||
      form?.userId === "" ||
      form?.password === ""
    ) {
      return setError(true);
    }
    if (err) {
      return;
    }
    if (form?.uniID?.length < 8 || form?.uniID?.length > 8) {
      return setError(true);
    }
    if (form?.password !== form?.cpassword) {
      return message.error("Password is not matching");
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/user/send-email-otp", form);
      if (res.data.success) {
        setOtp(res.data.data);
        setOtpField(true);
        setLoading(false);
        setCount(30); // Reset count
        const intervalId = setInterval(() => {
          setCount((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
          clearInterval(intervalId);
          setResend(true);
        }, 30000);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    generateRandomId();
  }, []);

  return (
    // <Layout breadcrumb={true} title={"Sign Up"}>
    <Layout>
      <div className="container-fluid register-container bg-white">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 order-2 order-lg-2">
            <div className="register-features">
              <div className="r-features">
                <div className="f-icon">
                  <AdsClickIcon className="icon" />
                </div>
                <div className="f-content">
                  <h5>Manage Your Ads</h5>
                  <p>
                  "Effortlessly manage your listings and boost visibility to reach more buyers."
                  </p>
                </div>
              </div>
              <div className="r-features">
                <div className="f-icon">
                  <ConnectWithoutContactIcon className="icon" />
                </div>
                <div className="f-content">
                  <h5>Get Contacts Easily</h5>
                  <p>
                  "Sign in now to effortlessly access contacts, manage your listings, and optimize your outcomes."
                  </p>
                </div>
              </div>
              <div className="r-features">
                <div className="f-icon">
                  <VerifiedUserIcon className="icon" />
                </div>
                <div className="f-content">
                  <h5>1000+ Verified Users</h5>
                  <p>
                  "Experience reliability and safety as we verify each member for an authentic and trustworthy environment."
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 mb-lg-0 col-12 col-sm-12 col-md-6 col-lg-6 order-1 order-lg-2">
            {otpField ? (
              <form className="register-form" onSubmit={handleSubmit}>
                <h2 className="text-center">Email Verification</h2>
                <div className="form-fields mb-3">
                  <input
                    onChange={(e) => setEnteredOtp(e.target.value)}
                    name="enteredOtp"
                    type="text"
                    className="form-control"
                    placeholder="Enter OTP"
                    value={enteredOtp}
                  />
                </div>
                <button className="register-btn">
                  {!verifyLoading && loading ? "Verifying..." : "Verify"}
                  {!verifyLoading && loading && (
                    <div
                      class="ms-2 spinner-grow spinner-grow-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                <div className="forgot-pass d-flex justify-content-between">
                  <h6 className="text-center my-2">
                    {resend ? "Resent Otp " : `Resend Otp in ${count} seconds`}
                    {resend && (
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          sendOtp();
                          setResend(false);
                          setVerifyLoading(true);
                        }}
                      >
                        Click here
                      </span>
                    )}
                  </h6>
                  <h6 className="text-center my-2">
                    Already a Customer? <Link to="/login">Click here</Link>
                  </h6>
                </div>
              </form>
            ) : (
              <form className="register-form" onSubmit={sendOtp}>
                <h2 className="text-center">Sign Up</h2>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.username}
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Name"
                  />
                  <small className="d-block mt-1 text-danger text-start">
                    {error && form?.username === "" && "Enter name"}
                  </small>
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <small className="d-block mt-1 text-danger text-start">
                    {error && form?.email === "" && "Enter email"}
                  </small>
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.mobile}
                    name="mobile"
                    type="text"
                    className="form-control"
                    placeholder="Mobile"
                  />
                  <small className="text-start d-block text-danger">
                    {error && form?.mobile === ""
                      ? "Enter mobile"
                      : err && isNaN(form?.mobile)
                      ? "Enter only numbers"
                      : err && form?.mobile.length > 10
                      ? "Enter only 10 digits "
                      : err && form?.mobile.length < 10
                      ? "Enter 10 digits"
                      : ""}
                  </small>
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.uniID}
                    name="uniID"
                    type="text"
                    className="form-control"
                    placeholder="University ID"
                  />
                  <small className="text-start d-block text-danger">
                    {error && form?.uniID === ""
                      ? "Enter Uni"
                      : errr && isNaN(form?.uniID)
                      ? "Enter only numbers"
                      : errr && form?.uniID?.length > 8
                      ? "Enter only 8 digits"
                      : errr && form?.uniID?.length < 8
                      ? "Enter atleast 8 digits"
                      : ""}
                  </small>
                </div>
                <div className="password-input form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.password}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                  />
                  <span
                    onClick={() => togglePasswordVisibility("one")}
                    className="password-toggle-icon"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                  </span>
                  <small className="d-block mt-1 text-danger text-start">
                    {error && form?.password === "" && "Enter password"}
                  </small>
                </div>
                <div className="password-input form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.cpassword}
                    name="cpassword"
                    type={showPasswordTwo ? "text" : "password"}
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                  <span
                    onClick={() => togglePasswordVisibility("two")}
                    className="password-toggle-icon"
                  >
                    {showPasswordTwo ? <FaEyeSlash /> : <FaEye />}{" "}
                  </span>
                  <small className="d-block mt-1 text-danger text-start">
                    {error && form?.cpassword === "" && "Enter password"}
                  </small>
                </div>
                <button className="register-btn">
                  {loading ? "Creating" : "Create Now"}
                  {loading && (
                    <div
                      class="ms-2 spinner-grow spinner-grow-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                <div className="forgot-pass d-flex justify-content-between">
                  <h6 className="text-center my-2">
                    Already a Customer? <Link to="/login">Click here</Link>
                  </h6>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
