import React from "react";
import Slider from "react-slick";
import IMAGES from "../../img/image";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarIcon from "@mui/icons-material/Star";
import "./Testimonials.css";

const Testimonials = () => {
  const agents = [
    {
      name: "Akhil Rana",
      desc: "I've had great success selling and buying various items. The platform's reliability and user-friendly design make it a go-to for all my exchange needs.",
    },
    {
      name: "Digvijay",
      desc: "Uniexchange provides a convenient platform for trading goods. The interface is intuitive, and the diverse listings cater to every need.",
    },
    {
      name: "Ashwani Dey",
      desc: "It's easy to list items for sale, and I've found incredible deals on items I needed. The platform's simplicity and vast selection keep me coming back.",
    },
    {
      name: "Utkarsh Gaur",
      desc: "I've sold items quickly and found fantastic deals on things I've been searching for. The platform's user-friendly layout and variety of listings are impressive.",
    },
    {
      name: "Md Ahtasham",
      desc: "I've sold items with ease and discovered hidden gems at fantastic prices. The platform's simplicity and wide array of products make it a winner",
    },
  ];

  var settings = {
    dots: true,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div key={i} className="star">
          <StarIcon className="star-icon" />
        </div>
      );
    }
    return stars;
  };

  return (
    <div className="testimonials-container">
      <span className="text-center d-block m-auto">
        <span className="text-green">
          <small>
            <b>TESTIMONIALS</b>
          </small>
        </span>
        <h4 className="fw-bold">What People Say About Us</h4>
      </span>
      <div className="testimonial-slider">
        <Slider {...settings}>
          {agents?.map((agent, index) => {
            return (
              <div key={index} className="testimonial">
                <div className="testimonial-content">
                  <div className="d-flex justify-content-between">
                    <h6 className="fw-bold">{agent.name}</h6>
                    <div className="d-flex">{renderStars()}</div>
                  </div>
                  <span>
                    <small>{agent.desc}</small>
                  </span>
                  <FormatQuoteIcon className="icon" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
