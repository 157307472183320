import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import IMAGES from "../img/image";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import NoResultFound from "../components/UI/NoResultFound";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useNavigate } from "react-router-dom";
import LayersIcon from "@mui/icons-material/Layers";
import "./MyListings.css";

const MyListings = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [properties, setProperties] = useState(null);
  const [data, setData] = useState(null);
  const [dealers, setDealers] = useState(null);
  const [soldForm, setSoldForm] = useState(false);
  const [error, setError] = useState(false);
  const [soldTo, setSoldTo] = useState("");
  const [bbId, setbbId] = useState("");
  const [tab, setTab] = useState(0);
  const [product, setProduct] = useState(null);

  async function getAllUserProperties() {
    try {
      const res = await axios.post(
        "/api/property/get-all-user-property",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const products = res.data.data?.filter(
          (item) => item.sold === false && item?.verified === true
        );
        setProperties(products.reverse());
        setData(res.data.data.reverse());
      } else {
        setProperties(null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDelete(e, id, bbId, images) {
    e.stopPropagation();
    e.preventDefault();
    const isConfirmed = window.confirm("Are you sure to delete?");
    if (isConfirmed) {
      try {
        const res = await axios.post(
          `/api/property/delete-property`,
          { id, bbId, images },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getAllUserProperties();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function markAsSoldPopup(e, item) {
    e.stopPropagation();
    e.preventDefault();
    setSoldForm(!soldForm);
    setbbId(item?.bbId);
  }

  async function handleMarkAsSold(e) {
    e.preventDefault();
    if (soldTo === "Select" || soldTo === "") {
      return setError(true);
    }
    if (bbId === "") {
      return message.error("something went wrong with product");
    }
    const obj = {
      bbId: bbId,
      soldTo: soldTo,
      product: product?.title,
      productImg: product?.images[0],
      user: "Outside",
      userEmail: "Outside",
      userMobile: "Outside",
      seller: user?.username,
      sellerEmail: user?.email,
      sellerMobile: user?.mobile,
      dealDone: true,
    };
    try {
      const res = await axios.post(`/api/deal/mark-as-sold`, obj, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setSoldForm(false);
        getAllUserProperties();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllDealers(bbId) {
    if (!user) {
      return message.error("Server Error");
    }
    try {
      const res = await axios.post(
        `/api/deal/get-dealers-list`,
        { email: user?.email, bbId: bbId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setDealers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleEdit(e, bbId) {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/edit-listing/${bbId}`);
  }

  function filterProducts(val, pending) {
    if (pending === false) {
      const filterProducts = data?.filter((item) => item?.verified === pending);
      setProperties(filterProducts);
    } else {
      const filterProducts = data?.filter(
        (item) => item?.sold === val && item?.verified === true
      );
      setProperties(filterProducts);
    }
  }

  useEffect(() => {
    getAllUserProperties();
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        {soldForm && (
          <div
            className="bckd"
            onClick={() => {
              setSoldForm(!soldForm);
              setDealers(null);
            }}
          ></div>
        )}
        {soldForm && (
          <form className="form mark-as-sold-form">
            <h4>Sold To</h4>
            <div className="form-fields mb-3">
              <select
                onChange={(e) => setSoldTo(e.target.value)}
                className="form-select"
                name="soldTo"
              >
                <option value="Select">Select Dealer</option>
                <option value="Outside Uniexchange">Outside Uniexchange</option>
                {dealers?.map((item, index) => {
                  return (
                    <option value={item?.userEmail}>{item?.userEmail}</option>
                  );
                })}
              </select>
              <span className="text-danger">
                <small>
                  {error &&
                    (soldTo === "" || soldTo === "Select") &&
                    "Please select"}
                </small>
              </span>
              <button onClick={handleMarkAsSold} className="bb-btn w-100 mt-3">
                Submit
              </button>
            </div>
          </form>
        )}
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">MY LISTING</h5>
          </div>
          <hr className="w-100 mt-0 mb-0" />
          <div className="mylistings-btn-container">
            <button
              onClick={() => {
                setTab(0);
                filterProducts(false, "");
              }}
              className={`bb-btn ${tab === 0 && "opacity"}`}
            >
              Live Products
            </button>
            <button
              onClick={() => {
                setTab(1);
                filterProducts(true, "");
              }}
              className={`bb-btn ${tab === 1 && "opacity"} mx-2`}
            >
              Sold Products
            </button>
            <button
              onClick={() => {
                setTab(2);
                filterProducts("", false);
              }}
              className={`bb-btn ${tab === 2 && "opacity"}`}
            >
              Non-Verified
            </button>
          </div>
          <div className="my-listings-container">
            <div className="my-listed-product-container">
              {properties && properties.length > 0 ? (
                properties.map((item, index) => {
                  return (
                    <div
                      className="product"
                      onClick={() => navigate(`/listing-info/${item?.bbId}`)}
                    >
                      <div className="pro-img">
                        <img src={item?.images[0]} alt="" />
                      </div>
                      <div className="bb-product-content">
                        <span className="text-secondary">
                          <LayersIcon />
                          Vehicle
                        </span>
                        <h6 className="m mb-0">{item?.title}</h6>
                        <hr className="m-0" />
                        <span className="d-flex justify-content-between">
                          <span>{item?.area}</span>
                          <span className="text-danger">
                            ₹{parseInt(item?.price).toLocaleString("en-US")}
                          </span>
                        </span>
                        {item?.verified && !item?.sold && (
                          <button
                            onClick={(e) => {
                              setProduct(item);
                              markAsSoldPopup(e, item);
                              getAllDealers(item?.bbId);
                            }}
                            className="order-2 order-lg-1 bb-btn mark-as-sold-btn"
                          >
                            Mark as sold
                          </button>
                        )}
                        <div className="actions">
                          {!item?.sold && (
                            <div className="order-1 order-lg-2">
                              <EditIcon
                                onClick={(e) => {
                                  handleEdit(e, item?.bbId);
                                }}
                                className="icon"
                              />
                              <DeleteOutlineIcon
                                onClick={(e) =>
                                  handleDelete(
                                    e,
                                    item?._id,
                                    item?.bbId,
                                    item?.images
                                  )
                                }
                                className="icon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoResultFound />
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default MyListings;
