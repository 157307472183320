import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DoneIcon from "@mui/icons-material/Done";
import "./AdminViewProperties.css";

const AdminViewProperties = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [property, setProperty] = useState(null);
  const [image, setImage] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getProperty() {
    try {
      const res = await axios.post(
        "/api/property/get-property",
        {
          bbId: params?.bbId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setProperty(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleVerify() {
    try {
      const res = await axios.post(
        "/api/admin/verify-property",
        {
          bbId: params?.bbId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-products");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProperty();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-view-property-container">
        <div className="page-title">
          <h3 className="m-0">{params?.bbId}</h3>
        </div>
        <hr />
        <div className="admin-product-info-container">
          <div className="product-info-container-left">
            <img
              className="main-img"
              src={property && property?.images[image]}
              alt=""
            />
            <div className="img-slider">
              {property?.images?.map((item, index) => {
                return (
                  <img
                    onClick={() => setImage(index)}
                    className={`${index === image && "active"}`}
                    src={item}
                    alt="image"
                  />
                );
              })}
            </div>
            <div className="product-about my-4">
              <h5>{property?.title}</h5>
              <hr />
              <h6>Description</h6>
              <span>
                <small>{property?.about}</small>
              </span>
            </div>
            <hr />
            <div className="product-features">
              <span>
                {property?.brand !== "" && property?.brand !== null && (
                  <p className="m-0">
                    <b>Brand:</b> {property?.brand}
                  </p>
                )}
              </span>
              <span>
                {property?.condition !== "" && property?.condition !== null && (
                  <p className="m-0">
                    <b>Condition:</b> {property?.condition}
                  </p>
                )}
              </span>
              {property?.bikeKm !== "" && property?.bikeKm !== null && (
                <span>
                  <p className="m-0">
                    <b>KM:</b> {property?.bikeKm}
                  </p>
                </span>
              )}
              {property?.bikeYear !== "" && property?.bikeYear !== null && (
                <span>
                  <p className="m-0">
                    <b>Year:</b> {property?.bikeYear}
                  </p>
                </span>
              )}
              {property?.size !== "" && property?.size !== null && (
                <span>
                  <p className="m-0">
                    <b>Size:</b> {property?.size}
                  </p>
                </span>
              )}
              {property?.color !== "" && property?.color !== null && (
                <span>
                  <p className="m-0">
                    <b>Color:</b> {property?.color}
                  </p>
                </span>
              )}
              {property?.author !== "" && property?.author !== null && (
                <span>
                  <p className="m-0">
                    <b>Author:</b> {property?.author}
                  </p>
                </span>
              )}
              {property?.edition !== "" && property?.edition !== null && (
                <span>
                  <p className="m-0">
                    <b>Edition:</b> {property?.edition}
                  </p>
                </span>
              )}
            </div>
            <hr />
            <h6>Features</h6>
            <div className="product-details">
              {property?.features?.map((item, index) => {
                return (
                  <div className="details">
                    <span>
                      <small>
                        <DoneIcon className="icon me-1" />
                        {item?.name}
                      </small>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="product-info-container-right form-sticky">
            <div className="product-price">
              <h4 className="m-0">
                <b>
                  ₹{property?.price} {property?.type === "Rent" && "Monthly"}
                </b>
              </h4>
            </div>
            <div className="more-details">
              <span className="d-flex justify-content-between">
                <small>{property?.area}</small>
                <small>
                  {new Date(property?.created).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </small>
              </span>
            </div>
            {!property?.sold && (
              <button
                className={`w-100 mt-3 bb-btn ${
                  property?.verified && "bg-danger"
                }`}
                onClick={handleVerify}
              >
                {property?.verified ? "Unverify Product" : "Verify Product"}
              </button>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminViewProperties;
