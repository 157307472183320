const IMAGES = {
  // UNIEXCHANGE //
  bread: require("../img/bread.jpeg"),
  // UNIEXCHANGE //
  //categories
  mobile: require("../img/home/mobile.png"),
  access: require("../img/home/access.png"),
  bike: require("../img/home/bike.png"),
  electronics: require("../img/home/electronics.png"),
  books: require("../img/home/books.png"),
  local: require("../img/home/household.png"),
  sports: require("../img/home/sports.png"),
  tshirt: require("../img/home/fashion.png"),
  household: require("../img/home/household.png"),
  ticket: require("../img/home/ticket.png"),
  services: require("../img/home/services.png"),
  // LOGO
  noresult: require("../img/noresult.jpg"),
  // ABOUT
  aboutimg: require("../img/about/aboutimg.jpeg"),
  /// PHONE SKIN
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),

  // HERO
  banner: require("./home/hero/banner.webp"),
  banner2: require("./home/hero/banner2.webp"),
  banner3: require("./home/hero/banner3.webp"),
  banner4: require("./home/hero/banner4.webp"),

  logo: require ("../img/logo.png")
};

export default IMAGES;
