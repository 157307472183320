import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IMAGES from "../img/image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { message } from "antd";
import LayersIcon from "@mui/icons-material/Layers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./Products.css";
import { useSelector } from "react-redux";

const Products = ({ title, homeLabel }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState(null);

  async function getAllProperties() {
    try {
      const res = await axios.get("/api/property/get-all-property");
      if (res.data.success) {
        const filterProducts = res.data.data.filter(
          (item) => item?.email !== user?.email
        );
        setProperties(filterProducts.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllProperties();
  }, []);

  return (
    <>
      <div className="products-container">
        {properties &&
          properties
            .map((item, index) => {
              return (
                <div
                  className="product"
                  onClick={() => navigate(`/listing-info/${item?.bbId}`)}
                >
                  <div className="pro-img">
                    <img src={item?.images[0]} alt="" />
                  </div>
                  <div className="bb-product-content">
                    <span className="text-secondary">
                      <LayersIcon />
                      {item?.category}
                    </span>
                    <h6 className="m mb-0">{item?.title}</h6>
                    <hr className="m-0" />
                    <span className="d-flex justify-content-between">
                      <span>{item?.area}</span>
                      <span className="text-danger p-price">
                        ₹{parseInt(item?.price).toLocaleString("en-US")}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })
            .slice(0, 12)}
        <div className="w-100">
          {properties?.length > 0 && (
            <button
              onClick={() => navigate("/listing")}
              className="bb-btn d-block m-auto"
            >
              View All
              <ArrowForwardIcon className="ms-1" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
