import React from "react";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import "./HowItWorksTwo.css";

const HowItWorksTwo = () => {
  return (
    <div className="why-choose-uni">
      <h2 className="text-center w-100 mb-1 mb-lg-4">
        <b>How It Works?</b>
      </h2>
      <div className="uni-exchange text-center">
        <div className="uni-icon">
          <PersonAddAlt1Icon className="icon text-danger" />
          <span>01</span>
        </div>
        <div className="uni-text">
          <h5>Create Account</h5>
          <p>
          Sign up and create your personalized account on Uniexchange.
          It only takes a few moments to get started.
          </p>
        </div>
      </div>
      <div className="uni-exchange text-center">
        <div className="uni-icon">
          <PostAddIcon className="icon text-warning" />
          <span>02</span>
        </div>
        <div className="uni-text">
          <h5>Post a Ad</h5>
          <p>
          Got something to sell? Share it with the community by posting an ad.
          Describe your item, set a price, and showcase it to potential buyers.
          </p>
        </div>
      </div>
      <div className="uni-exchange text-center">
        <div className="uni-icon">
          <LightbulbIcon className="icon text-success" />
          <span>03</span>
        </div>
        <div className="uni-text">
          <h5>Start Earning</h5>
          <p>
          Once your ad is live, sit back and watch your items find new homes. 
          Every successful sale means you're earning money and contributing to the exchange happening on Uniexchange.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksTwo;
