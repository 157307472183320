const Areas = {
  data: [
    {
      id: 1,
      name: "LPU-Campus",
      type: "Area",
    },
    {
      id: 2,
      name: "Phagwara",
      type: "Area",
    },
    {
      id: 3,
      name: "Law Gate",
      type: "Area",
    },
    {
      id: 4,
      name: "Hardaspur",
      type: "Area",
    },
    {
      id: 5,
      name: "Deepnagar",
      type: "Area",
    },
    {
      id: 6,
      name: "Jalandhar",
      type: "Area",
    },
    {
      id: 7,
      name: "Rama Mandi",
      type: "Area",
    },
    {
      id: 8,
      name: "Maheru",
      type: "Area",
    },
    {
      id: 9,
      name: "Chaheru",
      type: "Area",
    },
  ],
  metadata: {
    totalMatches: 131,
    remainingMatches: 0,
  },
  empty: false,
};

export default Areas;
