import React, { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const SocialShareButton = ({ shareUrl }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="d-block d-lg-none social-share-button">
      <div className="">
        <div className="fixed-share-buttons-container">
          {open ? (
            <CancelIcon
              onClick={() => setOpen(!open)}
              className="icon bg-danger"
            />
          ) : (
            <ShareIcon onClick={() => setOpen(!open)} className="icon" />
          )}
          <div className={`fixed-share-buttons ${open && "active"}`}>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={40} />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={40} />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={40} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={40} />
            </LinkedinShareButton>
            <PinterestShareButton url={shareUrl}>
              <PinterestIcon size={40} />
            </PinterestShareButton>
            <TelegramShareButton url={shareUrl}>
              <TelegramIcon size={40} />
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShareButton;
