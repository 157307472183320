import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import Products from "../components/Products";
import SubscriberForm from "../components/Home/SubscriberForm";
import axios from "axios";
import { message } from "antd";
import Testimonials from "../components/Home/Testimonails";
import PopularCategory from "../components/Home/PopularCategory";
import "./Home.css";

const Home = () => {
  const [count, setCount] = useState(null);
  const [productsKey, setProductsKey] = useState(Date.now()); // Key to force re-render

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getAllCount() {
    try {
      const res = await axios.get("/api/user/get-count");
      if (res.data.success) {
        setCount(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserData();
    getAllCount();
    // Reset products key to force reload
    setProductsKey(Date.now());
  }, []);

  return (
    <Layout>
      <HeroSection />
      <PopularCategory />
      <div className="home-categories-container">
        <h4 className="m-0 text-center">Fresh Recommended Ads</h4>
      </div>
      <Products key={productsKey} />
      <HowItWorks count={count} />
      {/* <Testimonials /> */}
      <SubscriberForm />
    </Layout>
  );
};

export default Home;