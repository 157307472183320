import React from "react";
import "./Pagination.css";

const Pagination = ({ currentPage, paginate, products, itemsPerPage }) => {
  const totalPages = Math.ceil(products?.length / itemsPerPage);

  const displayPageNumbers = () => {
    const limit = 5; // Adjust this limit as per your requirement
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - limit && i <= currentPage + limit)
      ) {
        pageNumbers.push(i);
      }
    }
    return pageNumbers;
  };

  const handlePageClick = (pageNumber) => {
    paginate(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
  };

  return (
    <div className="pagination">
      <div
        className={`page-number ${currentPage === 1 ? "disabled" : ""}`}
        onClick={() => handlePageClick(currentPage - 1)}
      >
        Prev
      </div>
      {displayPageNumbers().map((pageNumber) => (
        <div
          key={pageNumber}
          className={`page-number ${
            pageNumber === currentPage ? "active" : ""
          }`}
          onClick={() => handlePageClick(pageNumber)}
        >
          {pageNumber}
        </div>
      ))}
      <div
        className={`page-number ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={() => handlePageClick(currentPage + 1)}
      >
        Next
      </div>
    </div>
  );
};

export default Pagination;
