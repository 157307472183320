import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye and eye-slash icons
import "./Register.css";

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: "", password: "" });
  const [err, setErr] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [otpInput, setOtpInput] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [dbOtp, setDbOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const [count, setCount] = useState(30);
  const [resend, setResend] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    let intervalId;
    if (count > 0 && count <= 30 && !resend) {
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (count === 0) {
      setResend(true);
    }
    return () => clearInterval(intervalId);
  }, [count, resend]);

  useEffect(() => {
    if (count === 0) {
      setCount(30);
      setResend(true);
    }
  }, [count]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "mobile") {
      if (isNaN(value)) {
        setErr(true);
      } else if (value.length > 10) {
        setErr(true);
      } else if (value.length < 10) {
        setErr(true);
      } else {
        setErr(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.email === "" || form?.password === "") {
      return setError(true);
    }
    if (form?.email === "firefalls2004@gmail.com") {
      try {
        setLoading(true);
        const res = await axios.post("/api/user/admin", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin-dashboard");
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      try {
        setLoading(true);
        const res = await axios.post("/api/user/login", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/");
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res.data.message);
        }
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong");
      }
    }
  };

  const handleOtpChange = (e) => {
    if (isNaN(e.target.value)) {
      setOtpErr(true);
    } else if (e.target.value?.length > 6) {
      setOtpErr(true);
    } else if (e.target.value.length < 6) {
      setOtpErr(true);
    } else {
      setOtpErr(false);
    }
    setEnteredOtp(e.target.value);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (err) {
      return;
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/user/check-mobile-number", {
        mobile: form?.mobile,
      });
      if (res.data.success) {
        setOtpInput(true);
        setDbOtp(res.data.data);
        setLoading(false);
        message.success(res.data.message);
        setCount(30); // Reset counter to 30 seconds
        setResend(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (otpErr) {
      return;
    }
    if (parseInt(enteredOtp) === parseInt(dbOtp)) {
      try {
        setLoading(true);
        const res = await axios.post("/api/user/otp-login", {
          mobile: form?.mobile,
        });
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/");
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      return message.error("Incorrect OTP! Please Try Again");
    }
  };

  return (
    
      // <Layout breadcrumb={true} title={"Sign Up"}>
      <Layout>
      <div className="container-fluid register-container">
        <div className="row">
          {/* LEFT  */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 order-2 order-lg-2">
            <div className="register-features">
              <div className="r-features">
                <div className="f-icon">
                  <AdsClickIcon className="icon" />
                </div>
                <div className="f-content">
                  <h5>Manage Your Ads</h5>
                  <p>
                    "Effortlessly manage your listings and boost visibility to reach more buyers."
                  </p>
                </div>
              </div>
              <div className="r-features">
                <div className="f-icon">
                  <ConnectWithoutContactIcon className="icon" />
                </div>
                <div className="f-content">
                  <h5>Get Contacts Easily</h5>
                  <p>
                  "Sign in now to effortlessly access contacts, manage your listings, and optimize your outcomes."
                  </p>
                </div>
              </div>
              <div className="r-features">
                <div className="f-icon">
                  <VerifiedUserIcon className="icon" />
                </div>
                <div className="f-content">
                  <h5>Verified Users</h5>
                  <p>
                  "Experience reliability and safety as we verify each member for an authentic and trustworthy environment."
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* RIGHT  */}
          <div className="mb-5 mb-lg-0 col-12 col-sm-12 col-md-6 col-lg-6 order-1 order-lg-2">
            {/* EMAIL PASS LOGIN  */}
            {tab === 0 && (
              <form className="register-form" onSubmit={handleSubmit}>
                <h2 class = "text-center">Login</h2>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <small className="d-block mt-1 text-danger text-start">
                    {error && form?.email === "" && "Enter email"}
                  </small>
                </div>
                <div className="password-input form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.password}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                  </span>
                  <small className="d-block mt-1 text-danger text-start">
                    {error && form?.password === "" && "Enter password"}
                  </small>
                </div>
                <button className="register-btn text-white">
                  Login
                  {loading && (
                    <div
                      class="ms-2 spinner-grow spinner-grow-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                <div className="forgot-pass d-flex justify-content-between">
                  <h6 className="text-center my-2">
                    New Customer? <Link to="/register">Sign Up</Link>
                  </h6>
                  <h6 className="text-center my-2">
                    Forgot Password?{" "}
                    <Link to="/forgot-password">Click Here</Link>
                  </h6>
                </div>
                <div class="separator">
                  Login with Mobile OTP
                  <span
                    style={{ cursor: "pointer" }}
                    className="mx-2 text-primary"
                    onClick={() => setTab(1)}
                  >
                    click here
                  </span>
                </div>
              </form>
            )}

            {/* LOGIN WITH OTP  */}
            {tab === 1 && otpInput ? (
              <form className="register-form" onSubmit={handleVerifyOtp}>
                <h2>Login with OTP</h2>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleOtpChange}
                    value={enteredOtp}
                    name="otp"
                    type="text"
                    className="form-control"
                    placeholder="Enter 6 Digits Otp"
                  />
                  <small className="text-start d-block text-danger">
                    {otpErr && enteredOtp > 6
                      ? "Enter 6 digits only"
                      : otpErr && enteredOtp < 6
                      ? "Enter 6 digits"
                      : otpErr && "Enter numbers only"}
                  </small>
                </div>
                <button className="register-btn text-white">
                  Verify OTP
                  {loading && (
                    <div
                      class="ms-2 spinner-grow spinner-grow-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                <div className="forgot-pass d-flex justify-content-between">
                  <h6 className="text-center my-2">
                    {resend ? "Resent Otp " : `Resend Otp in ${count} seconds`}
                    {resend && (
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={handleSendOtp}
                      >
                        Click here
                      </span>
                    )}
                  </h6>
                  <h6 className="text-center my-2">
                    New Customer? <Link to="/register">Sign Up</Link>
                  </h6>
                </div>
                <div class="separator">
                  Login with Email
                  <span
                    style={{ cursor: "pointer" }}
                    className="mx-2 text-primary"
                    onClick={() => setTab(0)}
                  >
                    click here
                  </span>
                </div>
              </form>
            ) : (
              tab === 1 && (
                <form className="register-form" onSubmit={handleSendOtp}>
                  <h2 class="text-center">Login with OTP</h2>
                  <div className="form-fields mb-3">
                    <input
                      onChange={handleChange}
                      value={form?.mobile}
                      name="mobile"
                      type="text"
                      className="form-control"
                      placeholder="Registered Mobile No."
                    />
                    <small className="text-start d-block text-danger">
                      {error && form?.mobile === ""
                        ? "Enter mobile"
                        : err && isNaN(form?.mobile)
                        ? "Enter only numbers"
                        : err && form?.mobile.length > 10
                        ? "Enter only 10 digits "
                        : err && form?.mobile.length < 10
                        ? "Enter 10 digits"
                        : ""}
                    </small>
                  </div>
                  <button className="register-btn text-white">
                    Send OTP
                    {loading && (
                      <div
                        class="ms-2 spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  <div className="forgot-pass d-flex justify-content-between">
                    <h6 className="text-center my-2">
                      New Customer? <Link to="/register">Sign Up</Link>
                    </h6>
                  </div>
                  <div class="separator">
                    Login with Email
                    <span
                      style={{ cursor: "pointer" }}
                      className="mx-2 text-primary"
                      onClick={() => setTab(0)}
                    >
                      click here
                    </span>
                  </div>
                </form>
              )
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
