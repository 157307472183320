import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import "./AdminUsers.css";
import { useNavigate } from "react-router-dom";
import e from "cors";

const Subscribers = () => {
  const [data, setData] = useState(null);
  const [form, setForm] = useState({ subject: "", msg: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // get all users
  const getAllSubscriber = async () => {
    try {
      const res = await axios.get("/api/user/get-all-subscribers", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setData(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/admin/send-mail-to-all-users", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setForm({ subject: "", msg: "" });
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSubscriber();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Subscribers</h3>
        </div>
        <hr />
        {/* <div className="row">
          <div className="col-12">
            <div className="form-fields mb-3">
              <input
                onChange={handleChange}
                value={form?.subject}
                name="subject"
                type="text"
                placeholder="Enter Subject"
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-fields mb-3">
              <textarea
                onChange={handleChange}
                value={form?.msg}
                className="text-dark form-control"
                placeholder="Enter message"
                name="msg"
                rows="5"
              ></textarea>
            </div>
          </div>
        </div>
        <button onClick={handleSendMail} className="admin-btn text-dark">
          {loading ? "Sending.. " : "Send Mail"}
        </button> */}
        <hr />
        <div className="table-container">
          <table className="table user-table">
            <thead>
              <tr>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <small>{user?.email}</small>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Subscribers;
