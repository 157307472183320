import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import "./Layout.css";

const Layout = ({ children, breadcrumb, title }) => {
  const { pathname } = useLocation();
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line
    }, [pathname]);

    return null;
  };
  ScrollToTop();
  return (
    <React.Fragment>
      <Header />
      {breadcrumb && (
        <div className="breadcrumb m-0">
          <h4 className="m-0">{title}</h4>
          {/* <span>
            Home / <span className="text-white">{title}</span>
          </span> */}
        </div>
      )}
      <div className="body">{children}</div>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
