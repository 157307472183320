import IMAGES from "../img/image";

//! CATEGORY AND SUB CATEGORY
const category = [
  {
    // name: "Mobiles & Tablets",
    name: "Mobiles",
    img: IMAGES.mobile,
    sub: [
      {
        name: "Mobile Phones",
        brand: [
          { name: "iPhone" },
          { name: "Samsung" },
          { name: "Mi" },
          { name: "Vivo" },
          { name: "Oppo" },
          { name: "Realme" },
          { name: "Asus" },
          { name: "Blackberry" },
          { name: "Gionee" },
          { name: "Google Pixel" },
          { name: "Honor" },
          { name: "HTC" },
          { name: "Huawei" },
          { name: "Infinix" },
          { name: "Intex" },
        ],
      },
      {
        name: "Tablets",
        brand: [
          { name: "iPads" },
          { name: "Samsung" },
          { name: "Other Tablets" },
        ],
      },
      { name: "Accessories" },
    ],
  },
  {
    name: "Bikes",
    img: IMAGES.bike,
    sub: [
      {
        name: "Motorcycles",
        brand: [
          { name: "Harley-Davidson" },
          { name: "Yezdi" },
          { name: "BMW" },
          { name: "Kawasaki" },
          { name: "Revolt" },
          { name: "Ducati" },
          { name: "Jawa" },
          { name: "Benelli" },
          { name: "Aprilia" },
          { name: "Avanturaa Choppers" },
          { name: "BSA" },
          { name: "CFMoto" },
          { name: "Cleveland CycleWerks" },
          { name: "Eider" },
          { name: "Emflux Motors" },
          { name: "Escorts" },
          { name: "Evolet" },
          { name: "FB Mondial" },
          { name: "Hero Electric" },
          { name: "Hop Electric" },
          { name: "Husqvarna" },
          { name: "Hyosung" },
          { name: "Indian" },
          { name: "Keeway" },
          { name: "LML" },
          { name: "Mahindra" },
          { name: "Matter" },
          { name: "Moto Guzzi" },
          { name: "Moto Morini" },
          { name: "MV Agusta" },
          { name: "Norton" },
          { name: "Odysse" },
          { name: "Okinawa" },
          { name: "OLA" },
          { name: "PURE EV" },
          { name: "QJ Motor" },
          { name: "SWM" },
          { name: "Tork" },
          { name: "Triumph" },
          { name: "UM" },
          { name: "Vespa" },
          { name: "Victory" },
          { name: "Vida" },
          { name: "Zontes" },
          { name: "Bajaj" },
          { name: "Hero" },
          { name: "Hero Honda" },
          { name: "Honda" },
          { name: "KTM" },
          { name: "Royal Enfield" },
          { name: "Suzuki" },
          { name: "TVS" },
          { name: "Yamaha" },
          { name: "Other Brands" },
        ],
      },
      {
        name: "Scooters",
        brand: [
          { name: "Vespa" },
          { name: "Ampere" },
          { name: "Ather" },
          { name: "OLA" },
          { name: "Husqvarna" },
          { name: "Yamaha" },
          { name: "Vida" },
          { name: "Chetak" },
          { name: "BGauss" },
          { name: "BMW" },
          { name: "22Kymco" },
          { name: "Aprilia" },
          { name: "Avan Motors" },
          { name: "Benling" },
          { name: "Bounce" },
          { name: "EeVe" },
          { name: "Eider" },
          { name: "Evolet" },
          { name: "Gemopai" },
          { name: "Hero Electric" },
          { name: "Hero Honda" },
          { name: "Hyosung" },
          { name: "iVOOMi" },
          { name: "Joy e-bike" },
          { name: "Kinetic" },
          { name: "Lambretta" },
          { name: "LML" },
          { name: "Odysse" },
          { name: "Okaya" },
          { name: "Okinawa" },
          { name: "Piaggio" },
          { name: "PURE EV" },
          { name: "Simple Energy" },
          { name: "Techo Electra" },
          { name: "Twenty Two Motors" },
          { name: "UM" },
          { name: "Yo" },
          { name: "Bajaj" },
          { name: "Hero" },
          { name: "Honda" },
          { name: "Mahindra" },
          { name: "Suzuki" },
          { name: "TVS" },
          { name: "Other Brands" },
        ],
      },
      {
        name: "Bicycles",
        brand: [{ name: "Hercules" }, { name: "Hero" }, { name: "Others" }],
      },
      {
        name: "Spare Parts",
      },
    ],
  },
  {
    name: "Electronics",
    // name: "Electronics & Appliances",
    img: IMAGES.electronics,
    sub: [
      { name: "TVs & Audio" },
      { name: "Kitchen & Others" },
      { name: "Laptops & Computers" },
      { name: "Cameras & Lenses" },
      { name: "Computer Accessories" },
      { name: "Printers & Monitors" },
    ],
  },
  {
    name: "Fashion",
    img: IMAGES.tshirt,
    sub: [{ name: "Men" }, { name: "Women" }],
  },
  {
    name: "Books",
    img: IMAGES.books,
    sub: [
      { name: "Academics" },
      { name: "Non-Academics" },
      { name: "Stationery" },
      { name: "Others" },
    ],
  },
  {
    name: "Events",
    // name: "Events & Tickets",
    img: IMAGES.ticket,
    sub: [{ name: "Tickets & Pass" }],
  },
  {
    // name: "Sports & Hobbies",
    name: "Sports",
    img: IMAGES.sports,
    sub: [
      { name: "Gym & fitness" },
      { name: "Musical Instrument" },
      { name: "Sports Equipments" },
      { name: "Others" },
    ],
  },
  {
    name: "Accessories",
    img: IMAGES.access,
    sub: [
      { name: "Watches" },
      { name: "Jewelleries" },
      { name: "Headphones" },
      { name: "Other Accessories" },
    ],
  },
  {
    name: "Household",
    img: IMAGES.household,
    sub: [
      { name: "Kitchen Ware" },
      { name: "Bathroom Accessories" },
      { name: "Appliances" },
      { name: "Home Decor" },
      { name: "Furniture" },
      { name: "Other Household Items" },
    ],
  },
  {
    name: "Services",
    img: IMAGES.services,
    sub: [
      { name: "Freelanching" },
      { name: "Tutoring" },
      { name: "Web Development" },
      { name: "Graphic Design" },
      { name: "Content Writing" },
      { name: "Digital Marketing" },
      { name: "Event Planning" },
      { name: "Photography & Videography" },
      { name: "Printing & Copying" },
      { name: "Fitness Training" },
      { name: "Music Lessons" },
      { name: "Tours & Travel" },
      { name: "Laundry Services" },
      { name: "Delivery & Courier Services" },
      { name: "Store My Goods" },
      { name: "Packers & Movers" },
      { name: "Other Services" },
    ],
  },
];

export default category;
