import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./EditUser.css";

const EditUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error) {
      return;
    }
    if (
      user?.username === "" ||
      user?.profileId === "" ||
      user?.userId === "" ||
      user?.email === "" ||
      user?.mobile === ""
    ) {
      return setError(true);
    }
    try {
      const res = await axios.post("/api/admin/admin-edit-user", user, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-users");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (value?.length > 10) {
        setError(true);
      } else if (value?.length < 10) {
        setError(true);
      } else {
        setError(false);
      }
    }
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // get user
  const getUser = async () => {
    try {
      const res = await axios.post(
        "/api/admin/get-user",
        { id: params.id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setUser(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <form>
          <div className="page-title">
            <h3 className="m-0">Edit User</h3>
          </div>
          <div className="admin-edit-container">
            <div className="edit-admin-user-form">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-3">
                    <input
                      name="username"
                      placeholder="Username"
                      type="text"
                      className="form-control"
                      value={user?.username}
                      onChange={handleChange}
                      required
                    />
                    {error && user?.username === "" && (
                      <small className="text-danger">Enter username</small>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-3">
                    <input
                      name="profileId"
                      placeholder="Profile Id"
                      type="text"
                      className="form-control"
                      value={user?.profileId}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-3">
                    <input
                      placeholder="University Id"
                      type="text"
                      className="form-control"
                      name="uniID"
                      value={user?.uniID}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-3">
                    <input
                      name="email"
                      placeholder="Email"
                      type="text"
                      className="form-control"
                      value={user?.email}
                      onChange={handleChange}
                    />
                    {error && user?.email === "" && (
                      <small className="text-danger">Enter email</small>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-3">
                    <input
                      name="mobile"
                      placeholder="Mobile"
                      type="text"
                      className="form-control"
                      value={user?.mobile}
                      onChange={handleChange}
                    />
                    {error && user?.mobile === "" ? (
                      <small className="text-danger">Enter mobile</small>
                    ) : error && user?.mobile?.length > 10 ? (
                      <small className="text-danger">Only 10 numbers</small>
                    ) : error && user?.mobile?.length < 10 ? (
                      <small className="text-danger">Atleast 10 numbers</small>
                    ) : (
                      ""
                    )}
                  </div>
                  <button onClick={handleSubmit} className="bb-btn">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default EditUser;
