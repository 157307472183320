import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>Terms and Conditions</b>
        </h4>
      </div>
      <div className="terms-container">
        <h5>1. Acceptance of Terms</h5>
        <p>
          By accessing and using the website [www.uniexchange.in], you agree to
          be bound by these Terms and Conditions and all applicable laws and
          regulations. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site.
        </p>

        <h5 className="mt-5">2. Registration and User Accounts</h5>
        <p>
          a. Users must provide accurate and complete information during the
          registration process.
        </p>
        <p>
          b. Users are responsible for maintaining the confidentiality of their
          account information.
        </p>
        <p>
          c. Users agree to notify UniExchange immediately of any unauthorized
          use of their account.
        </p>

        <h5 className="mt-5">3. Listings of Goods</h5>
        <p>
          a. UniExchange serves as a platform for college students to list used
          goods for sale.
        </p>
        <p>
          b. UniExchange reserves the right to modify or remove any listing at
          its discretion.
        </p>
        <p>
          c. Users are responsible for the accuracy of the information provided
          in their listings.
        </p>

        <h5 className="mt-5">4. Transactions</h5>
        <p>
          a. UniExchange facilitates communication between buyers and sellers
          but is not directly involved in the transaction process.
        </p>
        <p>
          b. Transactions are solely between the parties involved, and
          UniExchange is not responsible for any disputes. Users are advised to
          exercise caution and perform due diligence when engaging in
          transactions.
        </p>

        <h5 className="mt-5">5. User Conduct</h5>
        <p>
          a. Users agree not to engage in any unlawful or prohibited activities
          on the website.
        </p>
        <p>
          b. Users must respect the privacy and rights of other users.
        </p>
        <p>
          c. Users are prohibited from posting any illegal, offensive, or
          inappropriate content.
        </p>

        <h5 className="mt-5">6. Intellectual Property</h5>
        <p>
          a. All content on the website is the property of UniExchange and is
          protected by intellectual property laws.
        </p>
        <p>
          b. Users may not reproduce, distribute, or use any content without
          explicit permission.
        </p>

        <h5 className="mt-5">7. Privacy Policy</h5>
        <p>
          a. The website's privacy policy outlines how user information is
          collected, used, and protected.
        </p>
        <p>
          b. Users are encouraged to review the privacy policy to understand how
          their data is handled.
        </p>

        <h5 className="mt-5">8. Limitation of Liability</h5>
        <p>
          a. UniExchange is not liable for any direct, indirect, or
          consequential damages arising from the use of the website.
        </p>
        <p>b. Users use the website at their own risk.</p>

        <h5 className="mt-5">9. Termination</h5>
        <p>
          a. UniExchange reserves the right to terminate or suspend user
          accounts for violation of these terms.
        </p>

        <h5 className="mt-5">10. Governing Law</h5>
        <p>
          a. These terms and conditions are governed by the laws of
          [India] without regard to its conflict of law principles.
        </p>

        <h5 className="mt-5">11. Changes to Terms</h5>
        <p>
          a. UniExchange reserves the right to update or modify these terms and
          conditions at any time.
        </p>
        <p>
          By using the UniExchange marketplace, you acknowledge that you have
          read, understood, and agreed to these terms and conditions.
        </p>
        <div className="mt-5">
          <p className="m-0">
            <b>UniExchange</b>
          </p>
          <p className="m-0">
            <>uniexchange.in@gmail.com</>
          </p>
          <p className="m-0">
            <>04 September 2024</>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
