import React, { useRef } from "react";
import Slider from "react-slick";
import "./PopularCategory.css";
import category from "../../category/categoryMenu";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const PopularCategory = () => {
  const navigate = useNavigate();
  const arrowRef = useRef();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    nextArrow: false,
    prevArrow: false,
    autoplay: true, // Enable autoplay
  autoplaySpeed: 3000, // Autoplay Speed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="popular-category">
      <div className="page-title">
        <h4>Popular Categories</h4>
        <div className="arrow-container">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <ArrowBackIosNewSharpIcon className="icon test-slider-left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <ArrowForwardIosSharpIcon className="icon test-slider-right" />
          </div>
        </div>
      </div>
      <div className="slider-container mt-4">
        <Slider ref={arrowRef} {...settings}>
          {category?.map((item, index) => (
            <div
              onClick={() => navigate(`/listing/${item?.name}`)}
              className="pci"
            >
              <div className="popular-category-item" key={index}>
                <div className="pc-img">
                  <img src={item.img} alt="" />
                </div>
                <p>{item.name}</p>
                {/* <p>{item.count}</p> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PopularCategory;
