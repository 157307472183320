import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import "./AddProperty.css";
import InfoIcon from "@mui/icons-material/Info";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { Modal, message } from "antd";
import axios from "axios";
import Areas from "../Areas";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import InventoryIcon from "@mui/icons-material/Inventory";
import IMAGES from "../img/image";
import category from "../category/categoryMenu";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerifiedIcon from "@mui/icons-material/Verified";

const AddProperty = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [error, setError] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useState({
    bbId: "",
    title: "",
    about: "",
    category: "",
    subCategory: "",
    condition: "",
    price: "",
    area: "",
    address: "",
    email: user?.email,
    mobile: user?.mobile,
    features: [{ name: "" }],
    // extra fields
    brand: "",
    //MOBILE
    mType: "",
    //BIKE
    bikeKm: "",
    bikeYear: "",
    //FASHION
    size: "",
    color: "",
    //BOOKS
    sem: "",
    department: "",
    courseCode: "",
    author: "",
    edition: "",
  });

  //! STATES FOR NUMBERS
  const [priceNan, setPriceNan] = useState(false);

  function generateRandomId() {
    const prefix = "UE";
    const numbers = Array.from({ length: 10 }, () =>
      Math.floor(Math.random() * 10)
    ).join("");
    setForm((prevForm) => ({
      ...prevForm,
      bbId: prefix + numbers,
    }));
  }

  useEffect(() => {
    generateRandomId();
  }, []);

  const handleFeatureChange = (e, index) => {
    const { value } = e.target;
    setForm((prevForm) => {
      const updatedFeatures = [...prevForm.features];
      updatedFeatures[index] = { name: value };
      return { ...prevForm, features: updatedFeatures };
    });
  };

  const handleAddFeatures = () => {
    setForm((prevForm) => ({
      ...prevForm,
      features: [...prevForm.features, { name: "" }],
    }));
  };

  const handleRemoveFeature = (index) => {
    setForm((prevForm) => {
      const updatedFeatures = [...prevForm.features];
      updatedFeatures.splice(index, 1);
      return { ...prevForm, features: updatedFeatures };
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
    if (name === "area") {
      const isValidArea = Areas?.data?.some((item) => item.name === value);
      if (isValidArea) {
        setError(false);
      } else {
        setError(true);
      }
    }
    if (name === "price") {
      if (isNaN(value)) {
        setPriceNan(true);
      } else {
        setPriceNan(false);
      }
    }
    if (e.target.value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (files.length > 8) {
      alert("You can only upload up to 8 files.");
      e.target.value = null;
      return;
    }
    const compressedImages = await Promise.all(
      Array.from(files).map(async (file) => {
        // Compress each image before uploading
        const compressedImage = await compressImage(file);
        return compressedImage;
      })
    );
    setSelectedFiles(compressedImages);
  };

  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 1, // Adjust the maximum size as needed
        maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
      };
      const compressedImage = await imageCompression(file, options);
      return compressedImage;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const formValidation = () => {
    if (
      form?.title.trim() === "" ||
      form?.category.trim() === "" ||
      form?.subCategory.trim() === "" ||
      form?.price.trim() === ""
    ) {
      return setFormErr(true);
    }
    if (form?.category !== "Services") {
      if (form?.condition.trim() === "") {
        return setFormErr(true);
      }
    }
    if (
      form?.category === "Mobiles & Tablets" &&
      form?.subCategory !== "Accessories"
    ) {
      if (form?.brand.trim() === "") {
        return setFormErr(true);
      }
    }
    // BIKES
    if (form?.category === "Bikes") {
      if (
        form?.subCategory === "Motorcycles" ||
        form?.subCategory === "Scooters"
      ) {
        if (
          form?.bikeKm.trim() === "" ||
          form?.bikeYear.trim() === "" ||
          form?.brand.trim() === ""
        ) {
          return setFormErr(true);
        }
      }
      if (form?.category === "Bicycles") {
        if (form?.brand.trim() === "") {
          return setFormErr(true);
        }
      }
    }
    // BOOKS
    if (form?.category === "Books") {
      if (form?.subCategory === "Handwritten Notes") {
        if (form?.sem.trim() === "" || form?.courseCode.trim() === "") {
          return setFormErr(true);
        }
      }
      if (form?.subCategory !== "Stationery") {
        if (form?.author.trim() === "") {
          return setFormErr(true);
        }
      }
      if (form?.edition.trim() === "") {
        return setFormErr(true);
      }
    }
    setTab(1);
    setFormErr(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (e) => {
    setIsModalOpen(false);
    handleSubmit(e);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const featuresString = JSON.stringify(form.features);
    formData.append("bbId", form?.bbId);
    formData.append("title", form?.title);
    formData.append("about", form?.about);
    formData.append("category", form?.category);
    formData.append("subCategory", form?.subCategory);
    formData.append("condition", form?.condition);
    formData.append("price", form?.price);
    formData.append("area", form?.area);
    formData.append("address", form?.address);
    formData.append("email", form?.email);
    formData.append("mobile", form?.mobile);
    formData.append("features", featuresString);
    // EXTRA FIELDS
    formData.append("brand", form?.brand);
    formData.append("mType", form?.mType);
    formData.append("bikeKm", form?.bikeKm);
    formData.append("bikeYear", form?.bikeYear);
    formData.append("author", form?.author);
    formData.append("edition", form?.edition);

    const filesArray = Array.from(selectedFiles);
    if (selectedFiles.length === 0) {
      message.error("No photo is selected");
      return;
    }

    if (
      form?.bbId === "" ||
      form?.title === "" ||
      form?.about === "" ||
      form?.category === "" ||
      form?.price === "" ||
      form?.area === "" ||
      form?.address === "" ||
      form?.features[0]?.name === ""
    ) {
      return setFormErr(true);
    }

    if (user === null) {
      return;
    }

    if (priceNan) {
      return;
    }

    for (let i = 0; i < filesArray.length; i++) {
      const file = filesArray[i];
      formData.append("images", file);
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/property/add-property", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setTab(2);
        setLoading(false);
        setFormErr(false);
        setForm({
          title: "",
          about: "",
          type: "",
          category: "",
          condition: "",
          price: "",
          area: "",
          address: "",
          email: "",
          mobile: "",
          features: [{ name: "" }],
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      email: user?.email,
      mobile: user?.mobile,
    }));
  }, [user]);

  const subCategory = category?.filter(
    (item) => item.name === form?.category
  )[0]?.sub;

  const brands = subCategory?.filter(
    (item) => item?.name === form?.subCategory
  )[0]?.brand;

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">ADD PRODUCT</h5>
          </div>
          <hr className="w-100 mt-0" />
          {tab !== 2 && (
            <div className="form-steps">
              <div className={`steps ${tab === 0 && "aline"}`}>
                {tab === 0 ? (
                  <LooksOneIcon className="icon" />
                ) : (
                  <CheckCircleIcon className="icon text-success" />
                )}
                <div>
                  <p>Step One</p>
                  <span>
                    <small>Basic info</small>
                  </span>
                </div>
              </div>
              <div className={`steps ${tab === 1 && "aline"}`}>
                {tab <= 1 ? (
                  <LooksTwoIcon className="icon" />
                ) : (
                  tab >= 2 && <CheckCircleIcon className="icon text-success" />
                )}

                <div>
                  <p>Step Two</p>
                  <span>
                    <small>Advance info</small>
                  </span>
                </div>
              </div>
            </div>
          )}
          {tab === 0 && (
            <div className="basic-info-property">
              <div>
                <InfoIcon className="me-1" />
                Basic Information
              </div>
              <hr />
              <div className="row">
                <div className="col-12 form-fields mb-2">
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={form?.title}
                    onChange={handleChange}
                    placeholder="Product Title"
                  />
                  <small className="text-danger">
                    {formErr && form?.title === "" && "Enter product title"}
                  </small>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Category</small>
                    </label>
                    <select
                      name="category"
                      className="form-select"
                      value={form?.category}
                      onChange={handleChange}
                    >
                      <option value="">Select Category</option>
                      {category?.map((item, index) => {
                        return <option value={item?.name}>{item?.name}</option>;
                      })}
                    </select>
                    <small className="text-danger">
                      {formErr && form?.category === "" && "Select category"}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Sub Category</small>
                    </label>
                    <select
                      name="subCategory"
                      className="form-select"
                      value={form?.subCategory}
                      onChange={handleChange}
                    >
                      <option value="">Select Sub Category</option>
                      {subCategory?.map((item, index) => {
                        return (
                          <option key={index} value={item?.name}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {formErr && form?.category === "" && "Select category"}
                    </small>
                  </div>
                </div>
                {/* EXTRA FIELDS */}
                {/* MOBILE  */}
                {form?.category === "Mobile & Tablets" &&
                  form?.subCategory === "Accessories" && (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Type</small>
                        </label>
                        <select
                          name="mType"
                          value={form?.mType}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value="">Select Type</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Tablet">Tablet</option>
                        </select>
                        <small className="text-danger">
                          {formErr && form?.mType === "" && "Select condition"}
                        </small>
                      </div>
                    </div>
                  )}
                {/* BIKE  */}
                {(form?.subCategory === "Motorcycles" ||
                  form?.subCategory === "Scooters") && (
                  <>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Bike KM</small>
                        </label>
                        <input
                          type="text"
                          name="bikeKm"
                          value={form?.bikeKm}
                          onChange={handleChange}
                          placeholder="Km driven"
                          className="form-control"
                        />
                        <small className="text-danger">
                          {formErr && form?.bikeKm === "" && "Select condition"}
                        </small>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Bike Year</small>
                        </label>
                        <input
                          name="bikeYear"
                          type="text"
                          value={form?.bikeYear}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Year of purchase"
                        />
                        <small className="text-danger">
                          {formErr &&
                            form?.bikeYear === "" &&
                            "Select condition"}
                        </small>
                      </div>
                    </div>
                  </>
                )}
                {/* FASHION  */}
                {/* {form?.category === "Fashion" && (
                  <>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Size</small>
                        </label>
                        <select
                          onChange={handleChange}
                          value={form?.size}
                          name="size"
                          className="form-select"
                        >
                          <option value="">Select Size</option>
                          <option value="S">S</option>
                          <option value="M">M</option>
                          <option value="L">L</option>
                          <option value="XL">XL</option>
                          <option value="XXl">XXL</option>
                          <option value="XXXL">XXXL</option>
                        </select>
                        <small className="text-danger">
                          {formErr && form?.size === "" && "Select condition"}
                        </small>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Color</small>
                        </label>
                        <input
                          name="color"
                          type="text"
                          value={form?.color}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter color"
                        />
                        <small className="text-danger">
                          {formErr && form?.color === "" && "Select condition"}
                        </small>
                      </div>
                    </div>
                  </>
                )} */}
                {/* BOOKS  */}
                {form?.category === "Books" && (
                  <>
                    {form?.subCategory === "Academics" && (
                      <>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-fields mb-2">
                            <label htmlFor="" className="form-lable mb-1">
                              <small>Semester</small>
                            </label>
                            <input
                              type="text"
                              name="sem"
                              value={form?.sem}
                              onChange={handleChange}
                              placeholder="Enter Semester"
                              className="form-control"
                            />
                            <small className="text-danger">
                              {formErr && form?.sem === "" && "Enter Semester"}
                            </small>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-fields mb-2">
                            <label htmlFor="" className="form-lable mb-1">
                              <small>Course Code</small>
                            </label>
                            <input
                              type="text"
                              name="courseCode"
                              value={form?.courseCode}
                              onChange={handleChange}
                              placeholder="Enter Course Code"
                              className="form-control"
                            />
                            <small className="text-danger">
                              {formErr &&
                                form?.courseCode === "" &&
                                "Enter Course Code"}
                            </small>
                          </div>
                        </div>
                      </>
                    )}
                    {form?.subCategory !== "Stationery" && (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-fields mb-2">
                          <label htmlFor="" className="form-lable mb-1">
                            <small>Author</small>
                          </label>
                          <input
                            type="text"
                            name="author"
                            value={form?.author}
                            onChange={handleChange}
                            placeholder="Enter author name"
                            className="form-control"
                          />
                          <small className="text-danger">
                            {formErr && form?.author === "" && "Enter author"}
                          </small>
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="form-fields mb-2">
                        <label htmlFor="" className="form-lable mb-1">
                          <small>Edition</small>
                        </label>
                        <input
                          type="text"
                          name="edition"
                          value={form?.edition}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter edition"
                        />
                        <small className="text-danger">
                          {formErr && form?.edition === "" && "Enter edition"}
                        </small>
                      </div>
                    </div>
                  </>
                )}

                {/* BRAND  */}
                {form?.category !== "Electronics & Appliances" &&
                  form?.category !== "Fashion" &&
                  form?.category !== "Books" &&
                  form?.category !== "Events & Tickets" &&
                  form?.category !== "Sports & Hobbies" &&
                  form?.category !== "Accessories" &&
                  form?.category !== "Household" &&
                  form?.category !== "Services" &&
                  form?.subCategory !== "Spare Parts" &&
                  form?.subCategory !== "Accessories" && (
                    <div className="col-12 col-md-6 col-lg-6 form-fields mb-2">
                      <label htmlFor="">
                        <small>Brand</small>
                      </label>
                      <select
                        onChange={handleChange}
                        className="form-select"
                        name="brand"
                      >
                        <option value="">Select Brand</option>
                        {brands?.map((item, index) => {
                          return (
                            <option value={item?.name}>{item?.name}</option>
                          );
                        })}
                      </select>
                      <small className="text-danger">
                        {formErr && form?.brand === "" && "Select Brand"}
                      </small>
                    </div>
                  )}

                {/* CONDITION  */}
                {form?.category !== "Services" && (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Condition</small>
                      </label>
                      <select
                        name="condition"
                        className="form-select"
                        value={form?.condition}
                        onChange={handleChange}
                      >
                        <option value="">Select Condition</option>
                        <option value="New">New</option>
                        <option value="Used">Used</option>
                      </select>
                      <small className="text-danger">
                        {formErr &&
                          form?.condition === "" &&
                          "Select condition"}
                      </small>
                    </div>
                  </div>
                )}
                {/* PRICE  */}
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="form-fields mb-2">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>Price</small>
                    </label>
                    <input
                      name="price"
                      placeholder="Enter price"
                      type="text"
                      className="form-control"
                      value={form?.price}
                      onChange={handleChange}
                    />
                    <small className="text-danger">
                      {formErr && form?.price === "" && "Enter price"}
                    </small>
                    <small className="text-danger">
                      {priceNan && "Enter only numbers"}
                    </small>
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <button
                  className="bb-btn"
                  onClick={() => {
                    formValidation("stepOne");
                  }}
                >
                  Next Step
                </button>
              </div>
            </div>
          )}

          {/* LOCATION  */}
          {tab === 1 && (
            <>
              <div className="basic-info-property">
                <div>
                  <CollectionsIcon className="me-1" />
                  Product Images
                </div>
                <hr />
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-lable mb-1">
                      <small>You can upload upto 8 images</small>
                    </label>
                    <input
                      aria-label="Select Image"
                      type="file"
                      accept=".jpg, .jpeg, .png, .webp"
                      name="image"
                      required
                      multiple
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {/* <input type="file" name="images" className="form-control" /> */}
                    <small className="text-danger">
                      Note: Images size should be less than 1 MB
                    </small>
                  </div>
                  <div className="mt-3 ol-12 form-fields mb-2">
                    <textarea
                      name="about"
                      cols="30"
                      rows="5"
                      placeholder="Product description"
                      className="form-control"
                      value={form?.about}
                      onChange={handleChange}
                    ></textarea>
                    <small className="text-danger">
                      {formErr && form?.about === "" && "Enter description"}
                    </small>
                  </div>
                </div>
              </div>
              <div className="basic-info-property">
                <div>
                  <EditLocationAltIcon className="me-1" />
                  Location / Contacts
                </div>
                <hr />
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Area</small>
                      </label>
                      <input
                        type="text"
                        list="areas"
                        name="area"
                        id="area"
                        value={form?.area}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Select Area"
                      />
                      <datalist id="areas" placeholder="">
                        {Areas?.data?.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </datalist>
                      <small className="text-danger">
                        {formErr && form?.area === ""
                          ? "Enter title"
                          : error && "Select from the list only"}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-8">
                    <div className="form-fields mb-2">
                      <label htmlFor="" className="form-lable mb-1">
                        <small>Address</small>
                      </label>
                      <input
                        type="text"
                        name="address"
                        placeholder="Enter full address"
                        className="form-control"
                        value={form?.address}
                        onChange={handleChange}
                      />
                      <small className="text-danger">
                        {formErr &&
                          form?.address === "" &&
                          "Enter full address"}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="basic-info-property">
                <div>
                  <InventoryIcon className="me-1" />
                  Product Features{" "}
                  <button className="bb-btn" onClick={handleAddFeatures}>
                    Add
                  </button>
                </div>
                <hr />
                <div className="row">
                  {form?.features?.map((item, index) => {
                    return (
                      <div
                        className="col-12 col-sm-12 col-md-4 col-lg-6"
                        key={index}
                      >
                        <div className="form-fields mb-2 d-flex">
                          <input
                            type="text"
                            name={`features[${index}].name`}
                            placeholder="Product feature"
                            className="form-control"
                            value={item.name}
                            onChange={(e) => handleFeatureChange(e, index)}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => handleRemoveFeature(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  <small className="text-danger">
                    {formErr &&
                      form?.features[0]?.name === "" &&
                      "Enter feature name"}
                  </small>
                </div>
                <hr />
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => setTab(0)}
                    className="bg-warning me-2 bb-btn mt-3"
                  >
                    Previous
                  </button>
                  <button
                    disabled={loading || error}
                    onClick={showModal}
                    className="bb-btn mt-3"
                  >
                  {loading ? "Submitting..." : "Post AD"}
                  </button>
                </div>
              </div>
            </>
          )}
          {tab === 0 ||
            (tab === 1 && (
              <div className="contact-us">
                <span>
                  Facing Problem while posting a property? Contact our team
                  support{" "}
                  <span onClick={() => navigate("/contact")}>Click Here</span>
                </span>
              </div>
            ))}

          {tab === 2 && (
            <div className="ad-success basic-info-property">
              <VerifiedIcon className="icon text-center text-success" />
              <h4 className="text-center">
                Your Ads is Successfully Added and <br /> will be visible after
                Verification
              </h4>
              <div className="d-flex">
                <button
                  className="bb-btn"
                  onClick={() => {
                    setTab(0);
                    navigate("/my-listings");
                  }}
                >
                  View All Ads
                </button>
              </div>
            </div>
          )}
        </div>
        <Modal
          title="Our Terms"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>
            By Clicking on "OK" you accept that the product you are posting is
            owned by you. If its not owned by you then Uni Exchange is not
            responsible for it.
          </p>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </Modal>
      </DashboardLayout>
    </Layout>
  );
};

export default AddProperty;
