import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";
import NoResultFound from "../components/UI/NoResultFound";
import { useNavigate } from "react-router-dom";

const OrderHistory = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState(null);

  async function getOrderHistory() {
    try {
      const res = await axios.post(
        "/api/deal/get-order-history",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setOrders(res.data.data.reverse());
        setData(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user) {
      getOrderHistory();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard">
          <div className="page-title">
            <h5 className="m-0">ORDER HISTORY</h5>
          </div>
          <hr className="w-100" />
          <table className="table contact-req-table">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Seller Name</th>
                <th>Product</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders?.length === undefined ? (
                <tr>
                  <td colSpan={100}>
                    <NoResultFound />
                  </td>
                </tr>
              ) : orders?.length === 0 ? (
                <tr>
                  <td colSpan={100}>
                    <NoResultFound />
                  </td>
                </tr>
              ) : (
                orders?.map((item, index) => {
                  return (
                    <tr className={`${item?.user === "Outside" && "d-none"}`}>
                      <td data-title="Srno">{index + 1}</td>
                      <td data-title="Name">
                        {user?.email === item?.sellerEmail && item?.user}
                        {user?.email !== item?.sellerEmail && item?.seller}
                      </td>
                      <td data-title="Product">
                        <small>{item?.product}</small>
                      </td>
                      <td data-title="Action">
                        <button
                          onClick={() =>
                            navigate(`/seller-profile/${item?.sellerProfileId}`)
                          }
                          className="bb-btn p-1"
                        >
                          Give Review
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
              <tr></tr>
            </tbody>
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default OrderHistory;
