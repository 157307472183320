import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import "./Account.css";
import { setUser } from "../redux/features/userSlice";

const Account = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    image: "",
    username: "",
    email: "",
    mobile: "",
    password: "",
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", image);
      formData.append("username", form?.username);
      formData.append("email", form?.email);
      formData.append("mobile", form?.mobile);
      if (form?.password !== "") {
        formData.append("password", form?.password);
      }
      const res = await axios.post("/api/user/user-profile-update", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        setForm({ ...form, password: "" });
        setImage(null);
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getUserData = async () => {
    setLoading(true);
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setForm({ ...res.data.data, password: "" });
          setLoading(false);
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-dashboard d-flex flex-column">
          <div className="page-title">
            <h5 className="m-0">MY ACCOUNT</h5>
          </div>
          <hr className="w-100 mt-0" />
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="form-fields mb-3">
                <br />
                {loading ? (
                  <div className="placeholder-glow">
                    <span className="placeholder col-1 placeholder-lg"></span>
                  </div>
                ) : (
                  <img className="user-img" src={form?.image} alt="userimg" />
                )}
              </div>
              <input
                className="my-3"
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <div className="col-12 col-sm-12">
              <div className="form-fields mb-3">
                <label htmlFor="" className="form-label">
                  Name
                </label>
                {loading ? (
                  <div className="placeholder-glow">
                    <span className="placeholder col-1 placeholder-lg"></span>
                  </div>
                ) : (
                  <h6>{form?.username}</h6>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <div className="form-fields mb-3">
                <label htmlFor="" className="form-label">
                  Email
                </label>
                {loading ? (
                  <div className="placeholder-glow">
                    <span className="placeholder col-2 placeholder-lg"></span>
                  </div>
                ) : (
                  <h6>{form?.email}</h6>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <div className="form-fields mb-3">
                <label htmlFor="" className="form-label">
                  Mobile
                </label>
                {loading ? (
                  <div className="placeholder-glow">
                    <span className="placeholder col-1 placeholder-lg"></span>
                  </div>
                ) : (
                  <h6>{form?.mobile}</h6>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <div className="form-fields mb-3">
                <label htmlFor="" className="form-label">
                  University ID
                </label>
                {loading ? (
                  <div className="placeholder-glow">
                    <span className="placeholder col-1 placeholder-lg"></span>
                  </div>
                ) : (
                  <h6>{form?.uniID}</h6>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="form-fields">
                <label htmlFor="" className="form-label">
                  Password
                </label>
                <input
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <button onClick={handleUpdate} className="bb-btn w-25">
            Update
          </button>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Account;
