import React, { useRef } from "react";
import IMAGES from "../../img/image";
import "./HeroSection.css";
import Slider from "react-slick";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const HeroSection = () => {
  const arrowRef = useRef();
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1, 
    infinite: true,
    nextArrow: <ArrowForwardIosSharpIcon className="icon hero-slider-right" />,
    prevArrow: <ArrowBackIosNewSharpIcon className="icon hero-slider-left" />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="hero-container">
    {/* //   <Slider ref={arrowRef} {...settings}>
    //     <div>
    //       <img src={IMAGES?.banner} className="d-block" alt="Banner 1" />
    //     </div>
    //     <div>
    //       <img src={IMAGES?.banner2} className="d-block" alt="Banner 2" />
    //     </div>
    //     <div>
    //       <img src={IMAGES?.banner3} className="d-block" alt="Banner 3" />
    //     </div>
    //     <div>
    //       <img src={IMAGES?.banner4} className="d-block" alt="Banner 4" />
    //     </div>
    //   </Slider> */}
      {/* <div className="hero-slider-btns">
        <div onClick={() => arrowRef.current.slickPrev()}>
          <ArrowBackIosNewSharpIcon className="icon hero-slider-left" />
        </div>
        <div onClick={() => arrowRef.current.slickNext()}>
          <ArrowForwardIosSharpIcon className="icon hero-slider-right" />
        </div>
      </div> */}
      <img src={IMAGES?.banner} className="d-block" alt="Banner" />
    </div>

  );
};

export default HeroSection;
