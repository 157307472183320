import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import axios from "axios";
import SearchContainer from "../SearchContainer";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import IMAGES from "../../img/image";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [homeLabel, setHomeLabel] = useState(null);
  const [products, setProducts] = useState(null);
  const [query, setQuery] = useState("");
  const [filterProduct, setFilterProduct] = useState(null);
  const [noResult, setNoResult] = useState(false);

  const handleFilterProduct = (e) => {
    const searchText = e.target.value.toLowerCase();
    setQuery(searchText);
  };

  useEffect(() => {
    const filteredProducts = products?.filter((item) =>
      item?.title?.toLowerCase()?.includes(query)
    );
    setFilterProduct(filteredProducts);
    setNoResult(query !== "" && filteredProducts?.length === 0);
  }, [query, products]);

  const getAllProducts = async () => {
    try {
      const res = await axios.get("/api/property/get-all-property");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const getUserData = async () => {
    try {
      const res = await axios.post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      dispatch(setUser(res.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <header className={`header ${location.pathname !== "/" && "active m-0"}`}>
        <div className="header-main">
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div className="logo">
            <span
              onClick={() => navigate("/")}
              className="fs-5"
            >
              Uniexchange
            </span>
            {/* <img src={IMAGES?.logo}></img> */}
            <div className="menus d-none d-md-none d-lg-block">
              <ul className="p-0">
                {/* <li><Link to="/">Home</Link></li> */}
                <li><Link to="/listing">Products</Link></li>
                {/* <li><Link to="/about">About</Link></li> */}
                {/* <li><Link to="/contact">Contact</Link></li> */}
                <li><Link to="/blog">Blog</Link></li>
                <Link to="/announcement">Announcements</Link>
                {/* {user && (
                  <li>
                    <Link to="/announcement">Announcements</Link>
                  </li>
                )} */}
              </ul>
            </div>
          </div>
          <div className="search-box-container d-none d-lg-block">
            <div className="form-fields">
              <input
                className="form-control"
                type="text"
                name="title"
                placeholder="Search...   eg: mobile laptop table fridge etc."
                onChange={handleFilterProduct}
                value={query}
              />
            </div>
            <div
              className={`${
                query === "" && "sactive"
              } search-box-container-listing`}
            >
              {query !== "" && (
                <div className="search-box-desktop">
                  {filterProduct &&
                    filterProduct.map((item, index) => (
                      <div
                        onClick={() => {
                          navigate(`/listing-info/${item?.bbId}`);
                          setSearch(false);
                          setQuery("");
                        }}
                        key={index}
                        className="search-product mb-3"
                      >
                        <div className="search-pro-img">
                          <img src={item?.images[0]} alt="Product" />
                        </div>
                        <div className="search-pro-content">
                          <h6 className="fw-bold">{item?.title}</h6>
                          <p>{item?.category}</p>
                          <p>₹{item?.price}</p>
                        </div>
                      </div>
                    ))}
                  <span className="text-center">
                    {noResult && <h2>No Result Found</h2>}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="w-25 d-none d-md-none d-lg-block">
            {user ? (
              <button
                className="loginbtn"
                onClick={() => navigate("/user-dashboard")}
              >
                <PersonIcon className="icon me-1" />
                Dashboard
              </button>
            ) : (
              <button className="loginbtn" onClick={() => navigate("/login")}>
                <PersonIcon className="icon me-1" />
                Login
              </button>
            )}
            <button
              className="post-btn"
              onClick={() =>
                user ? navigate("/add-product") : navigate("/login")
              }
            >
              <AddCircleIcon className="icon me-1" />
              <small>Post Ads</small>
            </button>
          </div>
          <div className="action-btns d-block d-lg-none">
            <div className="burger-icon d-block d-lg-none">
              <SearchIcon
                onClick={() => setSearch(!search)}
                className="icon fs-4"
              />
              {user
                ? location.pathname !== "/add-product" && (
                    <button
                      style={{ backgroundColor: "#00aaff", marginLeft: "5px" }}
                      className="sm-btn d-block d-md-none d-lg-none text-white"
                      onClick={() => navigate("/add-product")}
                    >
                      Post AD
                    </button>
                  )
                : location.pathname !== "/login" && (
                    <button
                      className="sm-btn d-block d-md-none d-lg-none login"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </button>
                  )}
              <DragHandleIcon
                onClick={() => setSideMenu(!sideMenu)}
                className="icon"
              />
            </div>
          </div>
        </div>
      </header>
      <SearchContainer search={search} setSearch={setSearch} />
    </>
  );
};

export default Header;
