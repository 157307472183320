import React from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import Testimonials from "../components/Home/Testimonails";
import HowItWorksTwo from "../components/Home/HowItWorksTwo.js";
import "./About.css";

const About = () => {
  return (
    <Layout breadcrumb={true} title={"About Us"}>
      <div className="container about-container">
        <div className="row">
          <div className="about-hero-content order-2 order-lg-1 col-12 col-sm-12 col-md-6 col-lg-6">
            <h3 className="fw-bold">Know more about Uniexchange</h3>
            <p>
            Welcome to Uniexchange, where we make things easy for college students.
            We started with a cool idea: to make a website just for college life.
            Whether you need books, gadgets, or cool stuff, Uniexchange has it all.
            Our team really cares about creating a safe and fun place for students to buy, sell, and connect.
            </p>
            <p>
            We keep things simple, reliable, and affordable so that every shopping experience is smooth and fun.
            Come join us and see how we're changing the way college students shop online.
            </p>
          </div>
          <div className="text-center order-1 mb-4 mb-lg-0 order-lg-2 col-12 col-sm-12 col-md-6 col-lg-6">
            <img
              className="about-img img-thumbnail"
              src={IMAGES.aboutimg}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <HowItWorks /> */}
      <HowItWorksTwo />
      <Testimonials />
    </Layout>
  );
};

export default About;
