import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>Privacy Policy</b>
        </h4>
      </div>
      <div className="terms-container">
        <h1>Privacy Policy</h1>
        <p>Effective Date: September 04, 2024</p>
        <p>
          This Privacy Policy outlines how we collect, use, and disclose your information when you use our services. It also explains your privacy rights and how the law protects you.
        </p>
        <p>
          By using our services, you consent to the collection and use of information in accordance with this Privacy Policy.
        </p>

        <h4 className="mt-5">Definitions and Interpretation</h4>
        <h5>Definitions</h5>
        <p>
          The following definitions apply regardless of whether they are used in singular or plural form:
        </p>
        <ul>
          <li>
            <p>
              <strong>Account:</strong> A unique account created for you to access our services or parts of them.
            </p>
          </li>
          <li>
            <p>
              <strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party. "Control" means ownership of 50% or more of shares, equity interest, or other voting securities.
            </p>
          </li>
          <li>
            <p>
              <strong>Company:</strong> Refers to Unexchange (referred to as "the Company", "We", "Us", or "Our").
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies:</strong> Small files placed on your device by a website to track your browsing history.
            </p>
          </li>
          <li>
            <p>
              <strong>Country:</strong> Refers to Maharashtra, India.
            </p>
          </li>
          <li>
            <p>
              <strong>Device:</strong> Any device that can access our service, such as a computer, mobile phone, or tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data:</strong> Any information that identifies or can be used to identify an individual.
            </p>
          </li>
          <li>
            <p>
              <strong>Service:</strong> Refers to our website.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Provider:</strong> Any natural or legal person who processes data on our behalf, including third-party companies or individuals hired to provide services, assist with our services, or analyze service usage.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data:</strong> Data collected automatically from using the service or from its infrastructure, such as page visit duration.
            </p>
          </li>
          <li>
            <p>
              <strong>Website:</strong> Refers to Uni Exchange, accessible from <a href="https://uniexchange.in" rel="external nofollow noopener" target="_blank">https://uniexchange.in</a>.
            </p>
          </li>
          <li>
            <p>
              <strong>You:</strong> The individual using the service or the entity on whose behalf the individual is accessing the service.
            </p>
          </li>
        </ul>

        <h4 className="mt-5">Collection and Use of Personal Data</h4>
        <h5>Types of Data Collected</h5>
        <h4>Personal Data</h4>
        <p>
          While using our service, we may ask you to provide certain personally identifiable information, including:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Usage Data</li>
        </ul>

        <h4>Usage Data</h4>
        <p>
          Usage Data is collected automatically when you use our service and may include information such as your device's IP address, browser type, pages visited, and time spent on pages.
        </p>
        <p>
          When accessing the service via a mobile device, we may collect information such as your device's unique ID, IP address, and mobile operating system.
        </p>

        <h4>Tracking Technologies and Cookies</h4>
        <p>
          We use cookies and similar tracking technologies to monitor activity and store information. Tracking technologies may include beacons, tags, and scripts.
        </p>
        <ul>
          <li>
            <strong>Cookies:</strong> Small files placed on your device. You can set your browser to refuse cookies, but this may prevent you from using some parts of our service.
          </li>
          <li>
            <strong>Web Beacons:</strong> Small electronic files used to count visitors or track the popularity of certain sections.
          </li>
        </ul>
        <p>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your device until deleted, while Session Cookies are deleted when you close your browser.
        </p>

        <h5>Uses of Personal Data</h5>
        <p>We use personal data for the following purposes:</p>
        <ul>
          <li>
            <strong>To provide and maintain our service</strong>, including monitoring usage.
          </li>
          <li>
            <strong>To manage your account</strong>, including registration and access to service functionalities.
          </li>
          <li>
            <strong>For contract performance</strong>, including the development and fulfillment of purchase contracts.
          </li>
          <li>
            <strong>To contact you</strong>, including notifications and updates.
          </li>
          <li>
            <strong>To provide you with news and offers</strong> related to our services unless you opt out.
          </li>
          <li>
            <strong>To manage your requests</strong>, including customer support.
          </li>
          <li>
            <strong>For business transfers</strong>, including mergers or acquisitions.
          </li>
          <li>
            <strong>For other purposes</strong>, such as data analysis and service improvement.
          </li>
        </ul>

        <h5>Sharing of Personal Data</h5>
        <p>We may share your personal information in the following circumstances:</p>
        <ul>
          <li>
            <strong>With Service Providers:</strong> To assist in service monitoring and analysis.
          </li>
          <li>
            <strong>For business transfers:</strong> In connection with company mergers or acquisitions.
          </li>
          <li>
            <strong>With Affiliates:</strong> Under similar privacy terms.
          </li>
          <li>
            <strong>With business partners:</strong> To offer products or promotions.
          </li>
          <li>
            <strong>With other users:</strong> In public areas or interactions.
          </li>
          <li>
            <strong>With your consent:</strong> For any other purposes agreed upon.
          </li>
        </ul>

        <h5>Retention of Personal Data</h5>
        <p>
          We will retain your personal data as long as necessary to fulfill the purposes outlined in this Privacy Policy. This includes compliance with legal obligations, resolving disputes, and enforcing agreements.
        </p>

        <h5>Transfer of Personal Data</h5>
        <p>
          Your data may be transferred to and processed in locations outside of your jurisdiction. We ensure that adequate controls are in place to protect your data during such transfers.
        </p>

        <h5>Deletion of Personal Data</h5>
        <p>
          You have the right to request the deletion of your personal data. You can manage your data through your account settings or by contacting us.
        </p>

        <h5>Security of Personal Data</h5>
        <p>
          We take reasonable measures to protect your personal data from unauthorized access, alteration, or disclosure.
        </p>

        <h5>Links to Other Websites</h5>
        <p>
          Our service may contain links to other websites. We are not responsible for the privacy practices of these external sites.
        </p>

        <h5>Children's Privacy</h5>
        <p>
          Our service is not directed to children under 18. We do not knowingly collect personal data from children under 18.
        </p>

        <h5>Changes to This Privacy Policy</h5>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.
        </p>

        <h5>Contact Us</h5>
        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:uniexchangeofficial@gmail.com">uniexchangeofficial@gmail.com</a>.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
