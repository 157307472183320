import React, { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AdminLayout from "./components/AdminLayout";
import "./AdminDeals.css";

const AdminDeals = () => {
  const [deals, setDeals] = useState(null);
  const [data, setData] = useState(null);
  const [uniqueDeals, setUniqueDeals] = useState(null);
  const [uniqData, setUniqData] = useState(null);
  const [bbId, setBbId] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allDealsById, setallDealsById] = useState(false);
  //Filter
  const [filter, setFilter] = useState(false);

  async function getAllDeals() {
    try {
      const res = await axios.get("/api/admin/admin-get-all-deals", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        const uniqueItems = res.data.data.reduce((acc, currentItem) => {
          const bbId = currentItem.bbId;
          const dealDone = currentItem.dealDone;
          if (!acc[bbId] || (dealDone && !acc[bbId].dealDone)) {
            acc[bbId] = currentItem;
          }
          return acc;
        }, {});
        const uniqueArray = Object.values(uniqueItems);
        setUniqueDeals(uniqueArray.reverse());
        setUniqData(uniqueArray);
        setDeals(res.data.data.reverse());
        setData(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function filterAds() {
    if (filter === "") {
      setUniqueDeals(uniqData);
    } else {
      const isSold = filter === "sold";
      const filterDeals = uniqData?.filter((item) => {
        return item?.dealDone === isSold;
      });
      setUniqueDeals(filterDeals);
    }
  }

  useEffect(() => {
    filterAds();
  }, [filter]);

  function getDeal(bbId) {
    const dealsById = deals?.filter((item) => item?.bbId === bbId);
    setallDealsById(dealsById);
  }

  useEffect(() => {
    getAllDeals();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Deals</h3>
        </div>
        <hr />

        {tab === 0 ? (
          <div className="table-container">
            <div className="tools">
              <select
                onChange={(e) => setFilter(e.target.value)}
                className="form-select text-dark my-3"
                id=""
              >
                <option value="">Select</option>
                <option value="live">Ongoing Deals</option>
                <option value="sold">Deal Done</option>
              </select>
            </div>
            <table className="table user-table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>Deal</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {uniqueDeals &&
                  uniqueDeals?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <img src={item?.productImg} alt="" />
                        </td>
                        <td>
                          <small>{item?.bbId}</small>
                        </td>
                        <td>
                          <small>{item?.product}</small>
                        </td>
                        <td>
                          <small>
                            {item?.dealDone === true ? "Yes" : "Ongoing"}
                          </small>
                        </td>
                        <td>
                          <small>
                            {new Date(item?.created).toLocaleString("default", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}
                          </small>
                        </td>
                        <td>
                          <small>
                            <RemoveRedEyeIcon
                              className="icon"
                              onClick={() => {
                                setTab(1);
                                setBbId(item?.bbId);
                                getDeal(item?.bbId);
                              }}
                            />
                          </small>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="deals-details-container">
            <div
              className="back-btn"
              onClick={() => {
                setTab(0);
                setBbId(null);
              }}
            >
              <ArrowBackIcon className="me-2" />
              Back
            </div>
            <div className="details-header">
              <span>Product ID: {bbId}</span>
              <span>Seller: {allDealsById[0]?.seller}</span>
              <span>Seller: {allDealsById[0]?.sellerEmail}</span>
              <span>Seller: {allDealsById[0]?.sellerMobile}</span>
            </div>
            <hr />
            <table className="table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Contact Access</th>
                  <th>Got the Deal?</th>
                </tr>
              </thead>
              <tbody>
                {allDealsById?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.user}</td>
                      <td>{item?.userEmail}</td>
                      <td>{item?.userMobile}</td>
                      <td>{item?.access ? "Yes" : "No"}</td>
                      <td>
                        {item?.soldTo === "Outside Uniexchange"
                          ? "Yes"
                          : item?.soldTo === item?.userEmail
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminDeals;
