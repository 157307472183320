import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import Pagination from "../components/Pagination";
import NoResultFound from "../components/UI/NoResultFound";
import LayersIcon from "@mui/icons-material/Layers";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import "./ProductPage.css";
import "./SellerProfile.css";
import defaultImage from "../img/default.png";

const SellerProfile = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [seller, setSeller] = useState(null);
  const [sellerProducts, setSellerProducts] = useState(null);
  const [review, setReview] = useState(null);
  const [rating, setRating] = useState(1);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);
  const [product, setProduct] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    const item = review?.filter((item) => item?.product === product);
    setSelectedProduct(item);
  }, [product]);

  const handleStarClick = (starNumber) => {
    setRating(starNumber);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div key={i} className="star" onClick={() => handleStarClick(i)}>
          {i <= rating ? (
            <StarIcon className="icon" />
          ) : (
            <StarBorderIcon className="icon" />
          )}
        </div>
      );
    }
    return stars;
  };

  async function getSeller() {
    try {
      const res = await axios.post("/api/user/get-seller-profile", {
        profileId: params?.id,
      });
      if (res.data.success) {
        setSeller(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getSellerProducts() {
    try {
      const res = await axios.post(
        "/api/property/get-all-user-property",
        {
          email: seller?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setSellerProducts(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllDeals() {
    try {
      const res = await axios.post(
        "/api/deal/deal-for-reviews",
        {
          userEmail: user?.email,
          sellerEmail: seller?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setReview(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmitReview() {
    try {
      if (product === "" || msg === "") {
        return setError(true);
      }
      const today = new Date();
      const reviewObject = {
        bbId: selectedProduct[0]?.bbId,
        sellerEmail: review[0]?.sellerEmail,
        user: review[0]?.user,
        userImg: user?.image,
        userEmail: review[0]?.userEmail,
        msg: msg,
        product: product,
        star: rating,
        date: today,
      };
      const res = await axios.post("/api/user/add-review", reviewObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setProduct("");
        setMsg("");
        setRating(1);
        setError(false);
        getSeller();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user) {
      getAllDeals();
    }
  }, [seller?.email, user]);

  useEffect(() => {
    getSeller();
  }, []);

  useEffect(() => {
    getSellerProducts();
  }, [seller?.email]);

  //! FILTER
  const [showFilter, setShowFilter] = useState(false);
  const [filterBackDrop, setFilterBackDrop] = useState(false);
  const [isSticky, setSticky] = useState(false);

  //! PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sellerProducts?.slice(indexOfFirstItem, indexOfLastItem);
  // Function to change the current page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 30);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout breadcrumb={true} title={"Seller Profile"}>
      <div className="product-page-container m-0">
        {/* DESKTOP FILTER  */}
        <div
          className={`product-filter-container d-block w-100 d-lg-none mb-5`}
        >
          <div className="seller-profile-container">
            <img className="seller-img" src={seller?.image} alt="seller-img" />
            <h4 className="m-0">{seller?.username}</h4>
            <span>
              <small>University ID: {seller?.uniID}</small>
            </span>
          </div>
          <hr />
          <h6>
            <small>Menus</small>
          </h6>
          <ul>
            <li
              className={`${tab === 0 && "active"}`}
              onClick={() => setTab(0)}
            >
              Recent Ads
            </li>
            <li
              className={`${tab === 1 && "active"}`}
              onClick={() => setTab(1)}
            >
              Seller Review
            </li>
            <li
              className={`${tab === 2 && "active"}`}
              onClick={() => setTab(2)}
            >
              Write Review
            </li>
          </ul>
        </div>

        <div className={`product-filter-container ${isSticky && "sticky"} `}>
          <div className="seller-profile-container">
            <img className="seller-img" src={seller?.image} alt="seller-img" />
            <h4 className="m-0">{seller?.username}</h4>
            <span>
              <small>University ID: {seller?.uniID}</small>
            </span>
          </div>
          <hr />
          <h6>
            <small>Menus</small>
          </h6>
          <ul>
            <li
              className={`${tab === 0 && "active"}`}
              onClick={() => setTab(0)}
            >
              Recent Ads
            </li>
            <li
              className={`${tab === 1 && "active"}`}
              onClick={() => setTab(1)}
            >
              Seller Review
            </li>
            <li
              className={`${tab === 2 && "active"}`}
              onClick={() => setTab(2)}
            >
              Write Review
            </li>
          </ul>
        </div>

        {/* PROPERTIES  */}
        {tab === 0 && (
          <div className="seller-product-container all-products-container products-container p-0">
            {currentItems
              ?.filter((item) => item.sold !== true)
              .map((item, index) => {
                return (
                  <div
                    className="bb-product product"
                    onClick={() => navigate(`/listing-info/${item?.bbId}`)}
                  >
                    <div className="pro-img">
                      <img src={item?.images[0]} alt="" />
                    </div>
                    <div className="bb-product-content">
                      <span className="text-secondary">
                        <LayersIcon />
                        {item?.category}
                      </span>
                      <h6 className="m mb-0">{item?.title}</h6>
                      <hr className="m-0" />
                      <span className="d-flex justify-content-between">
                        <span>{item?.area}</span>
                        <span className="text-danger">
                          ₹{parseInt(item?.price).toLocaleString("en-US")}
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            {/* Pagination component */}
            {currentItems?.length > 0 ? (
              <Pagination
                currentPage={currentPage}
                paginate={paginate}
                products={sellerProducts}
                itemsPerPage={itemsPerPage}
              />
            ) : (
              <NoResultFound />
            )}
          </div>
        )}

        {/* SELLER REVIEW  */}
        {tab === 1 && (
          <div className="seller-products-container src all-products-container products-container p-0">
            <div className="seller-reviews-container">
              {seller?.reviews?.map((item, index) => {
                const date = new Date(item?.date).toLocaleString("default", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                });
                const stars = [];
                for (let i = 1; i <= item?.star; i++) {
                  stars.push(<StarIcon className="icon" />);
                }
                return (
                  <div className="review-container">
                    <div className="review-img">
                      <img src={item?.userImg || defaultImage} alt="" />
                    </div>
                    <div className="review-content">
                      {stars}
                      <span className="ms-1">
                        <small>{item?.star} Star Ratings</small>
                      </span>
                      <h5 className="mt-3">
                        {item?.user}
                        <span>| {item?.product}</span>
                        <span>| {date}</span>{" "}
                      </h5>
                      <p>{item?.msg}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {seller?.reviews?.length === 0 && <NoResultFound />}
          </div>
        )}

        {/* WRITE REVIEW  */}
        {tab === 2 && (
          <div className="src all-products-container products-container p-0">
            <div className="write-review-container">
              {user ? (
                <div className="write-review">
                  <div className="d-flex">{renderStars()}</div>
                  <div className="form-fields mb-3">
                    <select
                      onChange={(e) => setProduct(e.target.value)}
                      value={product}
                      name="product"
                      className="form-select"
                    >
                      <option value="">Select</option>
                      {review?.map((item, index) => {
                        return (
                          <option key={index} value={item?.product}>
                            {item?.product}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">
                      <small>
                        {error && product === "" && "Select Product"}
                      </small>
                    </span>
                  </div>
                  <div className="form-fields mb-3">
                    <textarea
                      name="msg"
                      cols="30"
                      rows="5"
                      onChange={(e) => setMsg(e.target.value)}
                      value={msg}
                      placeholder="share your thoughts about this seller"
                      className="form-control"
                    ></textarea>
                    <span className="text-danger">
                      <small>
                        {error && product === "" && "Select Product"}
                      </small>
                    </span>
                  </div>
                  <button onClick={handleSubmitReview} className="bb-btn w-100">
                    Publish Review
                  </button>
                </div>
              ) : (
                <>
                  <h5>You don't Have any account</h5>
                  <p>
                    First login to your account to write down a review to this
                    seller
                  </p>
                  <button className="bb-btn">Login</button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SellerProfile;
