import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import HandshakeIcon from "@mui/icons-material/Handshake";
import GroupIcon from "@mui/icons-material/Group";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CampaignIcon from "@mui/icons-material/Campaign";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [deals, setDeals] = useState(null);
  const [orders, setOrders] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState(null);
  const [blogs, setBlogs] = useState(null);
  const [ann, setAnn] = useState(null);
  const [tabs, setTabs] = useState(0);

  const getAllUsers = async (e) => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setUsers(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllQueries = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-all-queries", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setQueries(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllDeals = async (e) => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/admin-get-all-deals", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setDeals(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllProducts = async () => {
    try {
      const res = await axios.get("/api/admin/admin-get-all-properties", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setProducts(res.data.data.reverse());
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBlogs = async () => {
    try {
      const res = await axios.get("/api/blog/get-all-blog", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setBlogs(res.data.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllAnn = async () => {
    try {
      const res = await axios.get("/api/ann/get-all-ann", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAnn(res.data.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllQueries();
    getAllDeals();
    getAllProducts();
    getAllUsers();
    getAllBlogs();
    getAllAnn();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Dashboard</h3>
      </div>
      <hr />
      <div className="admin-dashboard-container p-0">
        {/* USERS  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-users")}
        >
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{users?.length || 0}</b>
              )}
            </h1>
            <span className="text-muted">Total Users</span>
          </div>
          <GroupIcon className="icon" />
        </div>
        {/* PRODUCTS  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-products")}
        >
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{products?.length || 0}</b>
              )}
            </h1>
            <span className="text-muted">Total Products</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>
        {/* DEALS  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-deals")}
        >
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{deals?.length || 0}</b>
              )}
            </h1>
            <span className="text-muted">Total Deals</span>
          </div>
          <HandshakeIcon className="icon" />
        </div>
        {/* QUERIES  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-queries")}
        >
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>
                    {queries?.filter((item) => {
                      return item.status === "pending";
                    }).length || 0}
                  </b>
                )}
              </h1>
            </h1>
            <span className="title">Queries</span>
          </div>
          <HelpIcon className="icon" />
        </div>
        {/* LIVE DEALS  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-products/live")}
        >
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>
                    {products?.filter((item) => {
                      return item.sold === false;
                    }).length || 0}
                  </b>
                )}
              </h1>
            </h1>
            <span className="title">Live Ads</span>
          </div>
          <LiveTvIcon className="icon" />
        </div>
        {/* SOLD DEALS  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-products/sold")}
        >
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>
                    {products?.filter((item) => {
                      return item.sold === true;
                    }).length || 0}
                  </b>
                )}
              </h1>
            </h1>
            <span className="title">Sold Ads</span>
          </div>
          <DataThresholdingIcon className="icon" />
        </div>
        {/* Blogs  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-blog")}
        >
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>{blogs?.length || 0}</b>
                )}
              </h1>
            </h1>
            <span className="title">Blogs</span>
          </div>
          <RssFeedIcon className="icon" />
        </div>
        {/* Announcements  */}
        <div
          className="dash-card admin-dash-card"
          onClick={() => navigate("/admin-announcements")}
        >
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>{ann?.length || 0}</b>
                )}
              </h1>
            </h1>
            <span className="title">Announcements</span>
          </div>
          <CampaignIcon className="icon" />
        </div>
      </div>
      <div className="admin-recent-things">
        {/* <div className="recent-orders">
          <h5>Recent Orders</h5>
          <hr />
          <table className="table ">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Email</th>
                <th>Total</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {orders
                ?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <small>{item?.orderId}</small>
                      </td>
                      <td>
                        <small>{item?.email}</small>
                      </td>
                      <td>
                        <small>{item?.total}</small>
                      </td>
                      <td>
                        <small>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </td>
                      <td>
                        <RemoveRedEyeIcon
                          onClick={() =>
                            navigate(`/admin-view-order/${item?.orderId}`)
                          }
                          className="text-success icon"
                        />
                      </td>
                    </tr>
                  );
                })
                .slice(0, 5)}
            </tbody>
          </table>
        </div> */}
        <div className="recent-queries">
          <h5>Recent Queries</h5>
          <hr />
          <table className="table ">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {queries &&
                queries
                  ?.filter((item) => {
                    return item.status === "pending";
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{item?.name}</small>
                        </td>
                        <td>
                          <small>{item?.email}</small>
                        </td>
                        <td>
                          <small>{item?.subject}</small>
                        </td>
                        <td>
                          <small>{item?.msg}</small>
                        </td>
                      </tr>
                    );
                  })
                  .slice(0, 5)}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
