import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "../Footer/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CallIcon from "@mui/icons-material/Call";
import DraftsIcon from "@mui/icons-material/Drafts";
import axios from "axios";
import IMAGES from "../../img/image";
import category from "../../category/categoryMenu";

const Footer = () => {
  const location = useLocation();
  const params = useParams();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/user/subscribe", { email });
      if (res.data.success) {
        setMsg(true);
        setLoading(false);
      } else {
        setMsg(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setMsg(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mt-4 mt-lg-0 mb-4">
            <div className="contact-info">
              <div className="contact-info-content">
                <h5 className="m-0 mb-3">Uniexchange</h5>
                <p>Phagwara, Punjab, 144411</p>
                {/* <p>Phone: +91 9883084820</p> */}
                <p>Mail: uniexchangeofficial@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mt-4 mt-lg-0 mb-4">
            <div className="contact-info">
              <div className="contact-info-content">
                <h5 className="m-0 mb-3">Support</h5>
                <ul>
                  <li>
                    <Link to="/contact">Contact </Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQs </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="row">
              <div className="col-6">
                <div className="contact-info">
                  <div className="contact-info-content">
                    <h5 className="m-0 mb-3">Quick Links</h5>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/listing">Listings</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-6">
                <div className="contact-info">
                  <div className="contact-info-content">
                    <h5 className="m-0 mb-3">Category</h5>
                    <ul>
                      {category?.map((item, index) => {
                        return (
                          <li>
                            <Link to={`/listing/${item?.name}`}>
                              {item?.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={`footer-bottom ${
            location.pathname === `/listing-info/${params?.bbId}` && "pb"
          }`}
        >
          <span>
            Uniexchange - Classified Listing, All Rights Reserved © 2024.{" "}
            {/* <span className="text-white">Abdul Rakib</span> */}
          </span>
          {/* <span>
          Designed and Developed by{" "}
            <Link
              className="text-white"
              target="_blank"
              // to="https://aashirdigital.com"
            >
              ~Abdul Rakib
            </Link>
          </span> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
