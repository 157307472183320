import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import Slider from "react-slider";
import axios from "axios";
import Areas from "../Areas";
import Pagination from "../components/Pagination";
import NoResultFound from "../components/UI/NoResultFound";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LayersIcon from "@mui/icons-material/Layers";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductPage.css";
import category from "../category/categoryMenu";
import { useSelector } from "react-redux";

const MIN = 0;
const MAX = 500000;
const MINSQFT = 0;
const MAXSQFT = 500000;

const ProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [budget, setBudget] = useState([MIN, MAX]);
  const [budgetInput, setBudgetInput] = useState(false);
  const [minBudgetInput, setMinBudgetInput] = useState(MIN);
  const [maxBudgetInput, setMaxBudgetInput] = useState(MAX);

  const handleMinBudgetChange = (event) => {
    const newMinBudget = parseInt(event.target.value, 10);
    setMinBudgetInput(newMinBudget);
    setBudget([newMinBudget, budget[1]]);
  };

  const handleMaxBudgetChange = (event) => {
    const newMaxBudget = parseInt(event.target.value, 10);
    setMaxBudgetInput(newMaxBudget);
    setBudget([budget[0], newMaxBudget]);
  };
  const [sqft, setSqft] = useState([MINSQFT, MAXSQFT]);
  const [isSticky, setSticky] = useState(false);
  const [properties, setProperties] = useState(false);
  const [filter, setFilter] = useState({
    area: "",
    category: "",
    subCategory: "",
    condition: "",
  });

  const [filteredProperties, setFilteredProperties] = useState([]);
  const [error, setError] = useState(false);

  //! FILTER
  const [showFilter, setShowFilter] = useState(false);
  const [filterBackDrop, setFilterBackDrop] = useState(false);

  //! PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  // Function to change the current page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function handleChange(e) {
    const { value, name } = e.target;
    setFilter({ ...filter, [name]: value });
    if (name === "area") {
      const isValidArea = Areas?.data?.some((item) => {
        return item.name === value;
      });
      if (isValidArea) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }

  async function getAllProperties() {
    try {
      const res = await axios.get("/api/property/get-all-property");
      if (res.data.success) {
        if (user) {
          const filteredProperties = res.data.data.filter(
            (item) => item?.email !== user?.email
          );
          setProperties(filteredProperties.reverse());
          console.log(filteredProperties);
        } else {
          setProperties(res.data.data.reverse());
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleClearFilter() {
    setFilter({
      area: "",
      category: "",
      subCategory: "",
      condition: "",
    });
    setError(false);
    setBudget([0, 500000]);
    setSqft([0, 500000]);
  }

  useEffect(() => {
    getAllProperties();
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 30);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(properties)) {
      const newFilteredProperties = properties?.filter((property) => {
        // Check each filter criterion individually
        const areaMatch = filter.area ? property.area === filter.area : true;

        const categoryMatch = filter.category
          ? property.category === filter.category
          : true;

        const subCategoryMatch = filter.subCategory
          ? property.subCategory === filter.subCategory
          : true;

        const conditionMatch = filter.condition
          ? property.condition === filter.condition
          : true;

        const postedByMatch = filter.postedBy
          ? property.postedBy === filter.postedBy
          : true;
        // Check if price is within the budget range
        const budgetMatch =
          property.price >= budget[0] && property.price <= budget[1];
        // Check if area is within the sqft range
        return (
          areaMatch &&
          categoryMatch &&
          subCategoryMatch &&
          conditionMatch &&
          postedByMatch &&
          budgetMatch
        );
      });

      setFilteredProperties(newFilteredProperties);
    }
  }, [filter, properties, budget, sqft]);

  useEffect(() => {
    if (params?.category) {
      setFilter((prev) => ({ ...prev, category: params?.category }));
    }
  }, []);

  const subCategory = category?.filter(
    (item) => item.name === filter?.category
  )[0]?.sub;

  return (
    <Layout>
    {/* <Layout breadcrumb={true} title={"Ad List"}> */}
      <div className="product-page-container">
        {/* FILTER UI  */}
        <div
          className="mobile-menu d-block d-md-block d-lg-none"
          onClick={() => {
            setShowFilter(!showFilter);
            setFilterBackDrop(!filterBackDrop);
          }}
        >
          <div className="filter-btns">
            <div>
              <FilterAltIcon className="icon" />
              <span>Filter</span>
            </div>
            <span className="ms-5">
              <b>{currentItems?.length}</b>
            </span>
          </div>
        </div>
        {/* FILTER BACKDROP  */}
        <div
          className={`db-menu-backdrop ${filterBackDrop && "active"}`}
          onClick={() => {
            setShowFilter(false);
            setFilterBackDrop(false);
          }}
        ></div>
        <div
          className={`mobile-filter-container ${
            showFilter && "active"
          } d-block d-md-block d-lg-none`}
        >
          <CancelIcon
            onClick={() => {
              setShowFilter(!showFilter);
              setFilterBackDrop(!filterBackDrop);
            }}
            className="icon"
          />

          {/* MOBILE FILTER  */}
          <div className="mobile-filter">
            <div className="category">
              <div className="form-fields mb-2">
                <h6>
                  <small>Location</small>
                </h6>
                <input
                  type="text"
                  list="areas"
                  name="area"
                  value={filter?.area}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Choose Area"
                />
                <datalist id="areas" placeholder="">
                  {Areas?.data?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </datalist>
                <small className="text-danger">
                  {error && "Please select area from the list"}
                </small>
              </div>
            </div>
            <div className="category">
              {/* <h6>Categories</h6> */}
              <div className="form-fields mb-2">
                <h6>
                  <small>Category</small>
                </h6>
                <select
                  onChange={handleChange}
                  value={filter?.category}
                  className="form-select"
                  name="category"
                >
                  <option value="">Select Category</option>
                  {category?.map((item, index) => {
                    return <option value={item?.name}>{item?.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-fields mb-2">
                <h6>
                  <small>Sub Category</small>
                </h6>
                <select
                  name="subCategory"
                  className="form-select"
                  value={filter?.subCategory}
                  onChange={handleChange}
                >
                  <option value="">Select Sub Category</option>
                  {subCategory?.map((item, index) => {
                    return (
                      <option key={index} value={item?.name}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <hr />
            <h6>
              <small>Budget</small>
            </h6>
            <span>
              <b>
                ₹{budget[0]} - ₹{budget[1]}
              </b>
            </span>
            <Slider
              className={"slider"}
              value={budget}
              min={MIN}
              max={MAX}
              minDistance={2000}
              onChange={setBudget}
            />
            <hr />
            <h6>
              <small>Condition</small>
            </h6>
            <div className="form-fields mb-2">
              <select
                onChange={handleChange}
                value={filter?.condition}
                className="form-select"
                name="condition"
              >
                <option value="">Any</option>
                <option value="New">New</option>
                <option value="Used">Used</option>
              </select>
            </div>
            {filter?.type !== "" ||
            filter?.area !== "" ||
            filter?.category !== "" ||
            filter?.bedroom !== "" ||
            filter?.furnishing !== "" ||
            filter?.postedBy !== "" ? (
              <>
                <button
                  className="bb-btn w-100"
                  onClick={() => {
                    setShowFilter(!showFilter);
                    setFilterBackDrop(!filterBackDrop);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Search
                </button>
                <button
                  className="mt-2 bb-btn w-100"
                  onClick={handleClearFilter}
                >
                  Clear Filter
                </button>
              </>
            ) : (
              <button
                className="bb-btn w-100"
                onClick={() => {
                  setShowFilter(!showFilter);
                  setFilterBackDrop(!filterBackDrop);
                }}
              >
                Search
              </button>
            )}
          </div>
        </div>

        {/* DESKTOP FILTER  */}
        <div className={`product-filter-container ${isSticky && "sticky"}`}>
          <div className="category">
            <div className="form-fields mb-2">
              <h6>
                <small>Location</small>
              </h6>
              <input
                type="text"
                list="areas"
                name="area"
                value={filter?.area}
                onChange={handleChange}
                className="form-control"
                placeholder="Choose Area"
              />
              <datalist id="areas" placeholder="">
                {Areas?.data?.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </datalist>
              <small className="text-danger">
                {error && "Please select area from the list"}
              </small>
            </div>
          </div>
          <div className="category">
            {/* <h6>Categories</h6> */}
            <div className="form-fields mb-2">
              <h6>
                <small>Category</small>
              </h6>
              <select
                onChange={handleChange}
                value={filter?.category}
                className="form-select"
                name="category"
              >
                <option value="">Select Category</option>
                {category?.map((item, index) => {
                  return <option value={item?.name}>{item?.name}</option>;
                })}
              </select>
            </div>
            <div className="form-fields mb-2">
              <h6>
                <small>Sub Category</small>
              </h6>
              <select
                name="subCategory"
                className="form-select"
                value={filter?.subCategory}
                onChange={handleChange}
              >
                <option value="">Select Sub Category</option>
                {subCategory?.map((item, index) => {
                  return (
                    <option key={index} value={item?.name}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <h6>
              <small>Budget</small>
            </h6>
            <span
              style={{ cursor: "pointer" }}
              className="text-primary"
              onClick={() => setBudgetInput(!budgetInput)}
            >
              <small>{budgetInput ? "Hide" : "Enter Manually"}</small>
            </span>
          </div>
          <span>
            <b>
              ₹{budget[0]} - ₹{budget[1]}
            </b>
          </span>
          <Slider
            className={"slider"}
            value={budget}
            min={MIN}
            max={MAX}
            onChange={setBudget}
          />
          {budgetInput && (
            <div className={`budget-input-container`}>
              <input
                type="number"
                value={minBudgetInput}
                onChange={handleMinBudgetChange}
                className="budget-input"
                placeholder="min"
              />
              <input
                type="number"
                value={maxBudgetInput}
                onChange={handleMaxBudgetChange}
                className="budget-input"
                placeholder="max"
              />
            </div>
          )}
          <hr />
          <h6>
            <small>Condition</small>
          </h6>
          <div className="form-fields mb-2">
            <select
              onChange={handleChange}
              value={filter?.condition}
              className="form-select"
              name="condition"
            >
              <option value="">Any</option>
              <option value="New">New</option>
              <option value="Used">Used</option>
            </select>
          </div>
          <button className="bb-btn w-100" onClick={handleClearFilter}>
            Clear Filter
          </button>
        </div>
        {/* PROPERTIES  */}
        <div className="all-products-container products-container p-0">
          <h6 className="d-none d-lg-block m-0 w-100">
            {currentItems?.length} Ads
          </h6>
          {currentItems?.map((item, index) => {
            return (
              <div
                className="bb-product product"
                onClick={() => navigate(`/listing-info/${item?.bbId}`)}
              >
                <div className="pro-img">
                  <img src={item?.images[0]} alt="" />
                </div>
                <div className="bb-product-content">
                  <span className="text-secondary">
                    <LayersIcon />
                    {item?.category}
                  </span>
                  <h6 className="m mb-0">{item?.title}</h6>
                  <hr className="m-0" />
                  <span className="d-flex justify-content-between">
                    <span>{item?.area}</span>
                    <span className="text-danger p-price">
                      ₹{parseInt(item?.price).toLocaleString("en-US")}
                    </span>
                  </span>
                </div>
              </div>
            );
          })}
          {/* Pagination component */}
          {filteredProperties?.length > 0 ? (
            <Pagination
              currentPage={currentPage}
              paginate={paginate}
              products={filteredProperties}
              itemsPerPage={itemsPerPage}
            />
          ) : (
            <NoResultFound />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ProductPage;
